<script>
  import PageSection from "components/widgets/PageSection.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
</script>

<PageSection white>
  <div class="container">
    <div class="columns">
      <div class="column image-column desktop">
        <img
          alt="Liquid Noble on any platform"
          src="../../images/liquid_noble_preview.webp"
          class="preview-image"
        />
      </div>
      <div class="column text-column">
        <div class="heading">
          <img
            alt="Liquid Noble logo"
            src="../../images/LQN/LQN_logo_round_dark.svg"
            class="logo"
          />
          <Heading3
            ><span class="heading-text">
              <span
                >With Liquid Noble You Get a Digital Savings Account<br />Backed
                By Physical Gold</span
              >
            </span>
          </Heading3>
          <div class="column image-column mobile">
            <img
              alt="Liquid Noble on any platform"
              src="../../images/liquid_noble_preview.webp"
              class="preview-image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";
  .container {
    width: 100%;
    max-width: $size-laptop-max;
    height: 100%;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 128px;
    display: flex;

    @media only screen and (max-width: $buy-sell-stack-breakpoint) {
      padding-bottom: 0px;
    }

    .text-column {
      width: 100%;
      max-width: 536px;
      flex-grow: 1;
    }

    .heading {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 32px;
      display: flex;
      text-align: center;

      @media only screen and (max-width: $size-phone-max) {
        align-items: center;
      }

      .logo {
        width: 316px;
        @media only screen and (max-width: $size-phone-max) {
          width: 260px;
        }
      }

      .heading-text {
        &,
        span {
          display: flex;
          flex-wrap: wrap;
          color: black;
          column-gap: 5px;
          justify-content: center;
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          max-width: 472px;
          text-align: right;

          font-size: 32px;
          font-weight: 700;
          line-height: 195%;

          &.highlighted-text {
            color: #d9b44f;
          }
        }
      }
    }
    .image-column {
      width: 640px;
      flex-grow: 0;
    }
    .columns {
      width: 100vw;
      padding: 0px 48px 0px 0px;
      gap: 0px;

      @media only screen and (max-width: $size-tablet-max) {
        padding: 0px 16px;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .image-column {
          width: 100%;
        }
      }
    }
  }

  .desktop {
    @media only screen and (max-width: $size-tablet-max) {
      display: none;
    }
  }
  .mobile {
    display: none;
    width: 100%;
    .preview-image {
      width: 100%;
      height: auto;
      margin-bottom: 24px;
      max-width: 864px;
    }
    @media only screen and (max-width: $size-tablet-max) {
      display: flex;
    }
  }
  @media only screen and (max-width: $size-phablet-max) {
    .container {
      padding-bottom: 0px !important;
    }
    .container,
    .content {
      flex-direction: column !important;
      align-items: center;
    }
  }
</style>
