<script>
  import { Router, Route } from "svelte-routing";
  import { SvelteToast } from "@zerodevx/svelte-toast";
  import {
    PRODUCTION_HOSTNAMES,
    REGISTRATION_HOSTNAMES,
    REGISTRATION_URL,
    isReferralPortal,
    isDynamicReferralPage,
  } from "./utils/menu";
  import { initBranch } from "./modules/Branch.svelte";
  import Landing from "./components/pages/Landing/Landing.svelte";
  // import Register from "./components/pages/Register/Register.svelte";
  import Legal from "./components/pages/Documents/Legal.svelte";
  import GenericDocs from "./components/pages/Documents/Generic.svelte";
  import Contacts from "./components/pages/Contacts/Contacts.svelte";
  import Blog from "./components/pages/Blog/Blog.svelte";
  import RegisterLogin from "./components/pages/Login/Login.svelte";
  import Referral from "./components/pages/Referral/Referral.svelte";
  import ThankYou from "./components/pages/ThankYou/ThankYou.svelte";
  import AffiliateLanding from "./components/pages/AffiliateLanding/AffiliateLanding.svelte";

  const isRegistrationPage = REGISTRATION_HOSTNAMES.includes(
    window.location?.hostname
  );
  // Only render GA tags in production
  const renderAnalytics =
    isProduction &&
    (PRODUCTION_HOSTNAMES.includes(window.location?.hostname) ||
      isRegistrationPage);

  const isReferral = isReferralPortal();
  const isDynamicReferral = isDynamicReferralPage();

  if (isRegistrationPage && !isDynamicReferral && !isDynamicReferral) {
    window.location.href = REGISTRATION_URL;
  }

  // Svelte toast options
  const options = {
    theme: {
      "--toastMsgPadding": "20px 20px",
    },
  };

  export let url = "";

  initBranch();
</script>

<SvelteToast {options} />
<div class="root">
  {#if renderAnalytics}
    <script>
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-NS6NKP53");
    </script>

    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-2NWTM6KPJD"
    ></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "G-2NWTM6KPJD");
    </script>

    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-NS6NKP53"
        height="0"
        width="0"
        style="display:none;visibility:hidden"
      ></iframe></noscript
    >
  {/if}
  <section>
    <div class="content">
      <Router {url}>
        <Route path="/legal/*"><Legal /></Route>
        <Route path="/docs/*"><GenericDocs /></Route>
        <Route path="/blog"><Blog /></Route>
        <Route path="/register"><RegisterLogin /></Route>
        <Route path="/thank-you"><ThankYou /></Route>
        <Route path="/affiliate"><AffiliateLanding /></Route>
        <Route path="/auth"><RegisterLogin /></Route>
        <Route path="/contact-us"><Contacts /></Route>

        {#if isDynamicReferral && !isReferral}
          <Route path="/"><AffiliateLanding /></Route>
          <Route path="*"><AffiliateLanding /></Route>
        {/if}
        {#if isReferral}
          <Route path="/"><Referral /></Route>
          <Route path="*"><Referral /></Route>
        {/if}
        {#if !isDynamicReferral && !isReferral}
          <Route path="/"><Landing /></Route>
          <Route path="*"><Landing /></Route>
        {/if}
      </Router>
    </div>
  </section>
</div>

<style lang="scss">
  @import "./styles/global.scss";

  .root {
    width: 100%;
    padding: 0;
    overflow-x: hidden;
    font-family: "Heebo", "sans-serif";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    background: var(--color-bg-white);
    > section > div.content {
      display: flex;
      flex-direction: column;
    }
  }
</style>
