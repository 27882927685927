<script>
  import PageSection from "components/widgets/PageSection.svelte";
  import Heading1 from "components/elements/aliases/Heading1.svelte";
  import Btn from "components/elements/Btn.svelte";
</script>

<PageSection heroPurple>
  <div class="landing-hero">
    <div class="columns">
      <div class="column text-column">
        <Heading1 white
          ><span>
            Return to the<br />

            <span class="highlighted-text">Gold Standard</span>
            <br />With Liquid Noble
          </span></Heading1
        >
        <div class="subtitle">
          Help protect against inflation and volatility with digital gold you
          can trust. Get close-to-spot prices, and zero storage fees by
          registering below.
        </div>
        <div class="buttons">
          <Btn gold wide href="#affiliate-login">Register Now</Btn>
        </div>
        <div class="mobile">
          <img src="../../images/lqn_laptop.webp" class="dashboard-image" />
        </div>
      </div>
      <div class="column image-column desktop">
        <img src="../../images/lqn_laptop.webp" class="dashboard-image" />
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .landing-hero {
    @extend .generic-section;
    max-width: $size-laptop-max;
    gap: 128px;
    min-height: 827px;
    .columns {
      position: relative;
      justify-content: center;
      column-gap: 52px;
      margin: 0px;
      padding: 0px 48px;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
    .highlighted-text {
      color: #d9b44f;
    }
    .subtitle {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      max-width: 537px;
    }
    .column {
      gap: 24px;
      z-index: 1;
      &.text-column {
        width: 664px;
        max-width: 664px;
        padding-top: 128px;
        margin-left: 48px;

        @media only screen and (max-width: $size-phablet-min) {
          margin-left: 0px;
        }

        @media only screen and (max-width: $size-phablet-min) {
          padding-top: 124px;
        }
      }
      &.image-column {
        padding-top: 56px;
        width: 534px;
        height: 100%;
        padding-bottom: 24px;
        img {
          margin-left: -48px;
        }
      }
      .dashboard-image {
        height: 640px;
        width: fit-content;
        position: absolute;
      }
      .mobile {
        display: none;
        width: 100%;
        height: 544px;
        .dashboard-image {
          width: 100%;
          height: auto;
          margin-bottom: 24px;
          max-width: 664px;
        }
      }
      .image {
        width: 314px;
      }
    }
    .buttons {
      width: 100%;
      column-gap: 24px;
      padding-bottom: 24px;
      max-width: 585px;
      overflow: visible;
      flex-wrap: nowrap;

      @media only screen and (max-width: $size-tablet-max) {
        justify-content: center;
      }

      span {
        display: flex;
        gap: 12px;
      }
      .hero-input {
        width: 180px;
        height: 36px;
        border-radius: 4px;
        background-color: white;
        font-family: Heebo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        padding: 3px 10px;
        border: none;
        &::placeholder {
          color: var(--color-N50);
        }
      }
    }
    .hero-input + .hero-input {
      width: 242px;
    }
    @media only screen and (min-width: $size-phablet-min) and (max-width: $size-tablet-max) {
      .columns {
        text-align: center;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-start;
        .column {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .mobile {
          display: flex;
        }
        .desktop {
          display: none;
        }
        .image {
          margin-top: 48px;
          width: 260px;
        }
      }
    }
    @media only screen and (max-width: $size-phablet-min) {
      gap: 80px;

      .columns {
        flex-direction: column-reverse;
        text-align: center;
        .column {
          width: 100%;
          gap: 24px;
          &.desktop {
            display: none;
          }
          .image {
            margin-top: 48px;
            width: 180px;
          }
          .mobile {
            display: flex;
            height: 384px;
            .dashboard-image {
              max-width: 460px;
            }
          }
        }
        .buttons {
          gap: 12px;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          span {
            flex-direction: column;
          }

          .hero-input {
            width: 180px;
          }
        }
      }
    }
  }
</style>
