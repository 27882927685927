<script>
  import LQNLogo from "components/elements/LQNLogo.svelte";
  import TextButton from "components/elements/TextButton.svelte";
  import Icon from "components/elements/Icon.svelte";
  import Btn from "elements/Btn.svelte";
  import { COLOR_SECONDARY } from "buttonStyles";
  import { BUTTON_DATA, SIGN_IN_LINK, isReferralPortal } from "utils/menu";
  import { redirect } from "utils/navigation";

  export let padded = false;
  export let onMenuClick;
  export let onMenuClose;

  // References to TextButton objects
  let buttons = [];
  let activeButtonIdx = null;

  const registerLink = isReferralPortal() ? "#affiliate-login" : SIGN_IN_LINK;

  function openMenuLink(link) {
    if (link) {
      redirect(link);
    }
    onMenuClose();
  }
</script>

<div class="mobile-menu">
  <div class="header columns">
    <div class="logo">
      <LQNLogo round />
    </div>
    <div class="menu-close" on:click={onMenuClose}>
      <Icon name="x" colorName="N05" />
    </div>
  </div>
  <div class="content columns">
    <div class="column">
      <div class="header-button">
        <Btn
          transparent
          {padded}
          bdr_white
          onClick={() => openMenuLink(!isReferralPortal && "/register/")}
          href={registerLink}>Register Now</Btn
        >
      </div>
      {#each BUTTON_DATA as buttonData, i}
        <div class="menu-button">
          <TextButton
            noFill
            color={COLOR_SECONDARY}
            onClick={() => openMenuLink(buttonData.link)}
            href={buttonData.link}
          >
            {buttonData.label}
          </TextButton>
        </div>
        <!-- {#if i === activeButtonIdx}
          <div class="expanded-menu">
            <MenuContents {activeButtonIdx} />
          </div>
        {/if} -->
      {/each}
    </div>
  </div>
</div>

<style lang="scss">
  @import "../../styles/global.scss";

  .mobile-menu {
    @extend .generic-section;
    position: fixed;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    top: 0px;
    left: 0px;
    color: $purple;
    background: black;
    justify-content: flex-start;
    gap: 8px;
    z-index: 1000;
    cursor: auto;
    overflow-y: auto;

    .header {
      height: 72px;
      align-items: center;
      justify-content: space-between;
      @media only screen and (max-width: $size-phablet-max) {
        flex-direction: row;
        padding: 24px;
        margin-top: 8px;
      }

      .menu-close {
        margin-right: 8px;
        display: flex;
        width: 36px;
        height: 36px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .header-button {
      margin-bottom: 12px;
    }

    .column {
      padding: 0 8px 0 16px;
      width: 100%;

      .menu-button {
        height: 52px;
        display: flex;
        align-items: center;
        width: 100%;
        :global(.button) {
          height: 100%;
          width: 100%;
          color: $white;
        }
      }
      .expanded-menu {
        display: flex;
        background-color: $white;
        border-radius: 8px;
        width: 100%;
      }
      .top-button {
        margin-bottom: 24px;
        display: flex;
      }
    }
  }
</style>
