<script>
  import {
    THEME_LIGHT,
    THEME_DARK,
    COLOR_PRIMARY,
    BUTTON_COLORS,
    BUTTON_THEMES,
    BUTTON_STATES,
    LINK_SIZES,
    STATE_ACTIVE,
    STATE_DISABLED,
    SIZE_SMALL,
    SIZE_MEDIUM,
    SIZE_LARGE,
  } from "buttonStyles";
  import Anchor from "components/elements/Anchor.svelte";

  // Props
  export let dark = false;
  export let color = COLOR_PRIMARY;
  export let small = false;
  export let large = false;
  export let active = false;
  export let disabled = false;
  export let href = undefined;
  export let scrollTop = false;
  export let onClick = undefined;

  let theme = dark ? THEME_DARK : THEME_LIGHT;
  let size = SIZE_MEDIUM;
  if (large) {
    size = SIZE_LARGE;
  } else if (small) {
    size = SIZE_SMALL;
  }

  const colorMeta = BUTTON_COLORS[color];
  const themeMeta = BUTTON_THEMES[theme];
  const sizeMeta = LINK_SIZES[size];
  let stateMeta = null;

  if (active) {
    stateMeta = BUTTON_STATES[STATE_ACTIVE];
  }
  if (disabled) {
    stateMeta = BUTTON_STATES[STATE_DISABLED];
  }
  let stateClass = stateMeta?.class || "";
</script>

<Anchor class={$$props.class || ""} {href} {scrollTop} {onClick}>
  <div
    class="link {sizeMeta.class} {themeMeta.class} {colorMeta.class} {stateClass} {$$props.class ||
      ''}"
  >
    <slot />
  </div>
</Anchor>

<style lang="scss">
  @import "../../styles/global.scss";
  .link {
    flex-shrink: 0;
  }
</style>
