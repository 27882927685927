<script>
  import { GENERIC_DOCS_URL } from "utils/menu";
  import { GENERIC_DOCS_VERSION, GENERIC_DOCS_SOURCE } from "utils/document";
  import Document from "components/pages/Documents/Document.svelte";

  // Retrieve active page or default to intro
  const path = window.location.pathname?.replace("/docs/", "");
  export let params = { wild: path ?? "most-common" };
  export let defaultSubPage = "most-common";
</script>

<Document
  documentSourceUrl={GENERIC_DOCS_SOURCE}
  documentVersion={GENERIC_DOCS_VERSION}
  baseDocumentUrl={GENERIC_DOCS_URL}
  menuTitle="Documents"
  {params}
  {defaultSubPage}
/>
