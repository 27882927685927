<script>
  export let ref = undefined;
</script>

<svg
  {ref}
  width="21"
  height="21"
  viewBox="0 0 21 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="Social icon" clip-path="url(#clip0_22_486)">
    <path
      id="Vector"
      d="M12.5529 8.75921L19.8345 0.294922H18.109L11.7864 7.64434L6.73653 0.294922H0.912109L8.54849 11.4085L0.912109 20.2847H2.63772L9.31457 12.5234L14.6476 20.2847H20.472L12.5525 8.75921H12.5529ZM10.1895 11.5065L9.41573 10.3998L3.25948 1.59393H5.90991L10.8781 8.70055L11.6518 9.80721L18.1098 19.0447H15.4594L10.1895 11.5069V11.5065Z"
      fill="white"
    />
  </g>
  <defs>
    <clipPath id="clip0_22_486">
      <rect
        width="20"
        height="20"
        fill="white"
        transform="translate(0.692383 0.294922)"
      />
    </clipPath>
  </defs>
</svg>
