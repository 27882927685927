<script>
  import Icon from "components/elements/Icon.svelte";
  import MobileMenu from "components/widgets/MobileMenu.svelte";

  export let dark = true;

  let menuVisible = false;
  const onMenuClick = () => {
    menuVisible = !menuVisible;
  };
  const onMenuClose = () => {
    menuVisible = false;
  };
</script>

<div class="menu-button">
  <div on:click={onMenuClick}>
    <Icon name="menu" colorName={dark ? "N95" : "N05"} />
  </div>
  {#if menuVisible}
    <MobileMenu {onMenuClick} {onMenuClose} />
  {/if}
</div>

<style lang="scss">
  @import "../../styles/global.scss";

  .menu-button {
    display: none;
  }

  @media screen and (max-width: $mobile-menu-width) {
    .menu-button,
    .menu-button > div:first-child {
      display: flex;
      width: 36px;
      height: 36px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
</style>
