import { navigate } from "svelte-routing";

export const scrollToElementById = (id) => {
  const target = document.getElementById(id);
  target?.scrollIntoView();
};

export const redirect = (path) => {
  navigate(path, { replace: true });
};
