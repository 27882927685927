<script context="module">
  import * as animateScroll from "svelte-scrollto";
  import * as yaml from "js-yaml";
  import { navigate } from "svelte-routing";
  import * as marked from "marked";
  import { fetchTOC, fetchPageData } from "utils/document";
  import { IconsSVG } from "utils/icons";

  export const getPaginator = (currentPage, toc) => {
    const paginator = {
      previousLink: null,
      nextLink: null,
      previousLabel: null,
      nextLabel: null,
    };
    if (!currentPage || !Object.keys(toc ?? {}).length) {
      return paginator;
    }

    const pageList = Object.entries(toc)
      .map(([_, data]) => Object.entries(data.pages))
      .flat();

    let previousPageIdx, nextPageIdx;
    pageList.some(([page, _], idx) => {
      if (page === currentPage) {
        previousPageIdx = idx - 1;
        nextPageIdx = idx + 1;
        return true;
      }
    });
    const previous = pageList[previousPageIdx];
    const next = pageList[nextPageIdx];
    paginator.previousLink = previous?.[0];
    paginator.previousLabel = previous?.[1];
    paginator.nextLink = next?.[0];
    paginator.nextLabel = next?.[1];
    return paginator;
  };

  export const getSectionTarget = (sectionId) => {
    const selector = getSectionTargetSelector(sectionId);
    return document.querySelector(selector);
  };

  export const getSectionTargetSelector = (sectionId) => {
    sectionId = sectionId.replace("#", "");
    const target = document.getElementById(sectionId);
    return target ? `#${sectionId}` : `[name=${sectionId}]`;
  };

  export const scrollToTop = async () => {
    setTimeout(() => {
      document.body.scrollIntoView();
    }, 100);
  };

  export const scrollToSection = async (sectionId, delay = async () => {}) => {
    if (!sectionId) {
      return;
    }
    await delay();
    const selector = getSectionTargetSelector(sectionId);
    animateScroll.scrollTo({
      element: selector,
      offset: -24,
    });
  };

  export const loadTOC = async (baseUrl) => {
    const response = await fetchTOC(baseUrl);
    const contents = await response.text();
    try {
      return yaml.load(contents);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  export const toggleCopyLink = (link, copied = false) => {
    link.classList.remove("copied-link");
    link.classList.remove("copy-link");

    link.classList.add("copy-link");
    link.innerHTML = IconsSVG["link"];

    if (copied) {
      link.classList.add("copied-link");
      link.innerHTML += "<span>copied</span>";
    }
  };

  export const appendCopyLink = (target, targetURL) => {
    if (!target) {
      return;
    }
    const existingLinks = target.querySelector("a.copy-link");
    if (existingLinks) {
      return;
    }
    const copy = document.createElement("a");
    toggleCopyLink(copy, false);
    copy.onclick = () => {
      navigator.clipboard.writeText(`${window.location.origin}${targetURL}`);
      toggleCopyLink(copy, true);
      setTimeout(() => toggleCopyLink(copy, false), 2000);
    };
    target.appendChild(copy);
  };

  export const parseAnchors = async (baseUrl, currentPage, navigator) => {
    const anchors = document.getElementsByTagName("a");
    for (let anchor of anchors) {
      const href = anchor.getAttribute("href");
      if (href?.startsWith("#/")) {
        continue;
      }
      if (href?.startsWith("#") || href?.startsWith("../")) {
        const [page, linkSection] = href.split("#");
        anchor.removeAttribute("href");
        if (linkSection) {
          let newPage = page ? page.replace("../", "") : currentPage;
          newPage = newPage.replace("?", "");
          const fullPage = `${baseUrl}${newPage}`;
          const fullURL = `${fullPage}/section/${linkSection}`;
          const targetURL = `${fullURL}`;
          anchor.setAttribute("href", targetURL);

          anchor.onclick = () => {
            navigator(newPage, linkSection);
            navigate(fullURL, { replace: true });
          };

          const sectionTarget = getSectionTarget(linkSection);
          appendCopyLink(sectionTarget, targetURL);
        } else {
          anchor.classList.add("placeholder-link");
        }
      }
    }
  };

  export const getParsedPageData = async (page, baseUrl) => {
    const pageResponse = await fetchPageData(page, baseUrl);
    let pageRaw = await pageResponse.text();
    return marked.parse(pageRaw);
  };

  export const getParsedURLParams = (params, defaultSubPage) => {
    let activeSubPage = params.wild || defaultSubPage;
    let protocolSection;

    if (activeSubPage.includes("/section/")) {
      [activeSubPage, protocolSection] = activeSubPage.split("/section/");
    } else {
      protocolSection = undefined;
    }
    return [activeSubPage, protocolSection];
  };
</script>
