<script>
  import Anchor from "components/elements/Anchor.svelte";
  export let width = 182;
  export let height = 32;
  export let dark = false;
  export let round = false;

  let imgSrc = "/images/LQN/LQN_logo_horizontal.svg";
  if (dark) {
    imgSrc = "/images/LQN/LQN_logo_horizontal_dark.svg";
  }
  if (round) {
    imgSrc = "/images/LQN/LQN_logo_round.svg";
    width = 50;
    height = 50;
  }
</script>

<Anchor scrollTop href="/">
  <div class={round ? "round-logo-block" : ""}>
    <img
      draggable="false"
      src={imgSrc}
      alt="Liquid Noble Logo"
      width="{width}px"
      height="{height}px"
    />
    {#if round}
      <img
        class="lqn-title"
        draggable="false"
        src="/images/LQN/LQN_title.svg"
        alt="Liquid Noble"
        width="136px"
        height="24px"
      />
    {/if}
  </div>
</Anchor>

<style lang="scss">
  @import "../../styles/global.scss";

  .round-logo-block {
    display: flex;
    align-items: center;
    width: 204px;
    height: 50px;
    justify-content: space-between;

    .lqn-title {
      display: flex;
    }
    @media only screen and (max-width: $size-tablet-min) {
      width: 50px;
      .lqn-title {
        display: none;
      }
    }
  }
</style>
