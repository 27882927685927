<script>
    export let width = 80;
    export let height = 80;
    export let color = '#E6D097';
    export let ref = undefined;
  </script>
  
  <svg
    {ref}
    {width}
    {height}
    viewBox="0 0 {width} {height}"
    fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="shield">
<path id="Vector" d="M40 73.3334C40 73.3334 66.6667 60 66.6667 40V16.6667L40 6.66669L13.3333 16.6667V40C13.3333 60 40 73.3334 40 73.3334Z" stroke="{color}" stroke-width="9" stroke-linecap="round" stroke-linejoin="round"/> </g> </svg> 
  