<script>
  import { onMount } from "svelte";
  import { apiPostJSON } from "utils/api";

  import PageLayout from "components/PageLayout.svelte";
  import SectionHero from "./Sections/Hero.svelte";
  import SectionGetStarted from "../Landing/Sections/GetStarted.svelte";
  import SectionFaq from "../Landing/Sections/FAQ.svelte";

  let affiliateTitle = "";
  let affiliateSubtitle =
    "Help protect against inflation and volatility with digital gold you can trust. Get on the waitlist & register below.";
  let buttonText = "";
  let disclaimerShort = "";
  let disclaimerLong = "";
  let desktopImageSrc;
  let desktopImageAlt;
  let desktopImageStyle = "";
  let mobileImageSrc;
  let mobileImageAlt;
  let mobileImageStyle = "";

  const loadAffiliateData = async () => {
    try {
      const response = await apiPostJSON("/website/affiliate/", {
        hostname: window.location?.hostname,
      });
      if (!!response.cta_button_text) {
        affiliateTitle = response.page_title;
        affiliateSubtitle = response.page_subtitle;
        buttonText = response.cta_button_text;
        disclaimerShort = response.disclaimer_short;
        disclaimerLong = response.disclaimer_long;
        desktopImageSrc = response.desktop_image_src;
        desktopImageAlt = response.desktop_image_alt;
        desktopImageStyle = response.desktop_image_style;
        mobileImageSrc = response.mobile_image_src;
        mobileImageAlt = response.mobile_image_alt;
        mobileImageStyle = response.mobile_image_style;
      }
      if (!!response.error) {
        console.error(response.error);
      }
    } catch (error) {
      console.error(error);
    }
    if (!buttonText) {
      buttonText = "Join the Waitlist";
    }
  };

  onMount(() => {
    loadAffiliateData();
  });
</script>

<PageLayout blackHeader footerDisclaimer={disclaimerLong}>
  <SectionHero
    {affiliateTitle}
    {affiliateSubtitle}
    {buttonText}
    {disclaimerShort}
    {desktopImageSrc}
    {desktopImageAlt}
    {desktopImageStyle}
    {mobileImageSrc}
    {mobileImageAlt}
    {mobileImageStyle}
  />
  <SectionGetStarted />
  <SectionFaq />
</PageLayout>
