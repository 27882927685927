<script>
  import { Link } from "svelte-routing";
  export let href;
  export let scrollTop = false;
  export let onClick = undefined;

  let target;
  $: {
    if (href) {
      if (href.startsWith("http") || href.startsWith("mailto")) {
        target = "_blank";
      } else {
        target = "_self";
      }
    }
  }

  const onClickWrapper = () => {
    if (scrollTop) {
      window.scrollTo(0, 0);
    }
    onClick?.();
  };
</script>

{#if !!href}
  <a class={$$props.class} {href} {target} on:click={onClickWrapper}>
    <slot />
  </a>
  <!-- <Link class={$$props.class} to={href} {target} on:click={onClickWrapper}>
    <slot />
  </Link> -->
{:else}
  <div class={$$props.class} on:click={onClick}>
    <slot />
  </div>
{/if}

<style lang="scss">
  @import "../../styles/global.scss";

  a {
    display: flex;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.6);
  }
</style>
