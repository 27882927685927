<script>
  import PageSection from "components/widgets/PageSection.svelte";
  import Heading2 from "components/elements/aliases/Heading2.svelte";
</script>

<PageSection grid dark>
  <div class="container">
    <div class="content">
      <div class="left-column">
        <img src="../../images/payment.webp" class="image" />
      </div>
      <div class="right-column">
        <div class="heading">
          <Heading2>But It Wasn’t Practical in the Real World</Heading2>
        </div>
        <div class="paragraph">
          Investing in physical gold can be a safe-haven for wealth, but in its
          physical state, it isn’t practical for real world purchases like
          ordering coffee or paying for your groceries. It also has draw backs
          like:
        </div>
        <div class="features">
          <div class="feature">
            <div class="icon">
              <img
                alt="Slow access to cash when you may need it most"
                src="../images/slow_liquidity.webp"
              />
            </div>
            <div class="text">
              <h2>Slow access to cash when you may need it most</h2>
            </div>
          </div>
          <div class="feature">
            <div class="icon">
              <img
                alt="High storage and insurance fees"
                src="../images/high_fees.webp"
              />
            </div>
            <div class="text">
              <h2>High storage and insurance fees</h2>
            </div>
          </div>
          <div class="feature">
            <div class="icon">
              <img
                alt="Not easily divisible or practical"
                src="../images/divisibility.webp"
              />
            </div>
            <div class="text">
              <h2>Not easily divisible or practical</h2>
            </div>
          </div>
        </div>
        <div class="bottom-heading">
          <Heading2>Not Until Now...</Heading2>
        </div>
      </div>
    </div>
  </div>
</PageSection>

<!-- <div class="section-separator"></div> -->

<style lang="scss">
  @import "../../../../styles/global.scss";

  .section-separator {
    height: 32px;
    background-color: #ffffff;
  }
  .container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 128px;
    display: flex;

    .content {
      max-width: 1184px;
      padding-left: 24px;
      padding-right: 24px;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 32px;
      display: flex;
      width: 100%;
      .left-column {
        flex: 1 1 0;
        .image {
          margin-left: -64px;
          width: 600px;
          margin-top: -128px;
          margin-bottom: -630px;
          filter: drop-shadow(0px 0px 36px rgba(0, 0, 0, 0.12))
            drop-shadow(0px 30px 60px rgba(0, 0, 0, 0.08));

          @media only screen and (max-width: $size-phablet-max) {
            display: none;
          }
        }
      }

      .right-column {
        flex: 1 1 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;

        .heading {
          color: white;
          font-size: 48px;
          font-weight: 600;
          word-wrap: break-word;
          padding-bottom: 32px;
        }

        .paragraph {
          color: #dfe1e3;
          font-size: 17px;
          font-weight: 400;
          line-height: 27px;
          word-wrap: break-word;
          padding-bottom: 32px;

          .blue-text {
            color: var(--color-P40);
            &:hover {
              color: var(--color-P30);
            }
          }
        }

        .features {
          align-self: stretch;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 32px;
          display: flex;

          .feature {
            align-self: stretch;
            justify-content: flex-start;
            align-items: center;
            gap: 32px;
            display: flex;

            .icon {
              width: 40px;
              height: 40px;
              position: relative;

              img {
                width: 40px;
                height: auto;
                left: 0px;
                top: 0px;
                position: absolute;
              }

              .yellow-box {
                width: 14px;
                height: 28px;
                left: 13px;
                top: 6px;
                position: absolute;
                background: #e5c77b;
              }
            }

            .text {
              flex: 1 1 0;
              align-self: center;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              display: flex;

              h2 {
                color: white;
                font-size: 18px;
                font-weight: 600;
                word-wrap: break-word;
                margin: 0px;
              }
            }
          }

          .divider {
            width: 40px;
            height: 40px;
            position: relative;
            .yellow-box {
              width: 38.12px;
              height: 38.12px;
              left: 0.94px;
              top: 0.94px;
              position: absolute;
              background: #e5c77b;
            }
            .blue-box {
              width: 38.12px;
              height: 38.12px;
              left: 0.94px;
              top: 0.94px;
              position: absolute;
              background: #4491a7;
            }
          }
        }
        .bottom-heading {
          margin-top: 32px;
        }
      }
    }
  }
  @media only screen and (max-width: $size-phablet-max) {
    .content {
      flex-direction: column-reverse;

      .left-column {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .image {
          margin: 48px 0px 24px !important;
        }
      }
    }
  }
</style>
