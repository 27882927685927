<script>
  export let ref = undefined;
</script>

<svg
  {ref}
  width="21"
  height="21"
  viewBox="0 0 21 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="Group">
    <path
      id="Vector"
      d="M19.2119 0.294922H2.16895C1.35254 0.294922 0.692383 0.939453 0.692383 1.73633V18.8496C0.692383 19.6465 1.35254 20.2949 2.16895 20.2949H19.2119C20.0283 20.2949 20.6924 19.6465 20.6924 18.8535V1.73633C20.6924 0.939453 20.0283 0.294922 19.2119 0.294922ZM6.62598 17.3379H3.65723V7.79101H6.62598V17.3379ZM5.1416 6.49023C4.18848 6.49023 3.41895 5.7207 3.41895 4.77148C3.41895 3.82227 4.18848 3.05273 5.1416 3.05273C6.09082 3.05273 6.86035 3.82227 6.86035 4.77148C6.86035 5.7168 6.09082 6.49023 5.1416 6.49023ZM17.7353 17.3379H14.7705V12.6973C14.7705 11.5918 14.751 10.166 13.2275 10.166C11.6846 10.166 11.4502 11.373 11.4502 12.6191V17.3379H8.48926V7.79101H11.333V9.0957H11.3721C11.7666 8.3457 12.7354 7.55273 14.1768 7.55273C17.1807 7.55273 17.7353 9.5293 17.7353 12.0996V17.3379Z"
      fill="white"
    />
  </g>
</svg>
