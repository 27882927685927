<script>
  import PageSection from "components/widgets/PageSection.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Btn from "components/elements/Btn.svelte";

  let features = [
    {
      title: "Sign up",
      text: `Create a free Liquid Noble account in just a few minutes`,
      imageUrl: "../images/sign_up.svg",
    },
    {
      title: "Deposit",
      text: `Fund your account with AUD via wire transfer`,
      imageUrl: "../images/deposit.svg",
    },
    {
      title: "Buy",
      text: `Purchase vaulted gold and other precious metals`,
      imageUrl: "../images/buy.svg",
    },
  ];
</script>

<PageSection heroPurple>
  <div class="container">
    <div class="content">
      <div class="heading">
        <Heading3
          ><span class="heading-text">
            <span>3 Easy Steps to Get Started</span>
          </span>
        </Heading3>
      </div>
      <div class="features">
        {#each features as feature (feature)}
          <div class="feature">
            <div class="icon">
              <img src={feature.imageUrl} alt={feature.title} />
            </div>
            <div class="title">{feature.title}</div>
            <div class="text">{feature.text}</div>
          </div>
        {/each}
      </div>
      <div class="buttons">
        <Btn gold href="/register">Diversify with Liquid Noble Now</Btn>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";
  .container {
    width: $size-laptop-max;
    height: 100%;
    padding: 60px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 128px;
    display: flex;

    .content {
      padding: 0px 48px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 35px;
      display: flex;
      max-width: 1200px;

      .heading {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 32px;
        display: flex;
        text-align: center;

        .heading-text {
          &,
          span {
            display: flex;
            flex-wrap: wrap;
            color: #ffffff;
            column-gap: 5px;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            max-width: calc(100vw - 16px);
            &.highlighted-text {
              color: #d9b44f;
            }
          }
        }
      }
      .features {
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        width: 100vw;
        row-gap: 60px;
        column-gap: 60px;
        display: flex;
        padding: 0 32px 0px 32px;

        .feature {
          flex-shrink: 0;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          display: flex;
          row-gap: 25px;
          width: 100%;
          max-width: 320px;

          .icon {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &,
            img {
              width: 96px;
            }
          }
          .title {
            color: #ffffff;
            text-align: center;
            font-size: 22px;
            font-weight: 700;
          }
          .text {
            text-align: center;
            color: #ffffff;
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            min-height: 80px;
            max-width: 224px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: $size-phablet-max) {
    .container,
    .content {
      flex-direction: column !important;
      align-items: center;
      .features {
        flex-direction: column !important;
        align-items: center !important;
      }
    }
  }
</style>
