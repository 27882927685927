<script>
  import PageSection from "components/widgets/PageSection.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Btn from "components/elements/Btn.svelte";

  let features = [
    {
      title: "All Tokens Are Backed By Precious Metals",
      text: `We invest in gold before we ever ask our customers to do the same. This is not only for good-faith and transparency with our customers, but also allows us to offer 24/7 trading and instant order fulfillment. This backing also gives our tokens their tangible value that allows our users to return to the gold standard.`,
      imageUrl: "../images/transparency_lqn.webp",
    },
    {
      title: "Multiple Levels of Accountability & Security",
      text: `Unlike traditional banks & off-chain solutions, the non-custodial nature of our wallets means that only the original owner can access or manipulate the assets. The permanent, on-chain proof of ownership and audit trails cannot be tampered with or removed by Liquid Noble, or any other party.`,
      imageUrl: "../images/transparency_levels.webp",
    },
    {
      title: "Proof of Reserves Audited Biannually",
      text: `Each digital precious metal token on Liquid Noble is backed 1-to-1 by the corresponding physical precious metal (1 unit = 1 t oz) stored in our vault. Vaults are regularly audited by independent chartered accountants and confirmation statements are provided at regular intervals.`,
      imageUrl: "../images/transparency_audits.webp",
    },
  ];
</script>

<PageSection white>
  <div class="container">
    <div class="content">
      <div class="heading">
        <Heading3
          ><span class="heading-text">
            <span>Unparraleled Transparency & Security</span>
          </span>
        </Heading3>
      </div>
      <div class="features">
        {#each features as feature (feature)}
          <div class="feature">
            <div class="icon">
              <img src={feature.imageUrl} alt={feature.title} />
            </div>
            <div class="title">{feature.title}</div>
            <div class="text">{feature.text}</div>
          </div>
        {/each}
      </div>
      <div class="buttons">
        <Btn gold href="/register">Discover the Liquid Noble Difference</Btn>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";
  .container {
    width: $size-laptop-max;
    height: 100%;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 128px;
    display: flex;

    @media only screen and (max-width: $buy-sell-stack-breakpoint) {
      padding-bottom: 0px;
    }

    .content {
      padding: 0px 48px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 50px;
      display: flex;
      max-width: 1200px;

      .heading {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 32px;
        display: flex;
        text-align: center;

        .heading-text {
          &,
          span {
            display: flex;
            flex-wrap: wrap;
            color: black;
            column-gap: 5px;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            max-width: calc(100vw - 16px);
            &.highlighted-text {
              color: #d9b44f;
            }
          }
        }
      }
      .features {
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        width: 100vw;
        row-gap: 60px;
        column-gap: 60px;
        display: flex;
        padding: 0 32px 32px 32px;

        .feature {
          flex-shrink: 0;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          display: flex;
          row-gap: 25px;
          width: 100%;
          max-width: 416px;
          padding: 26px;

          .icon {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &,
            img {
              width: 168px;
            }
          }
          .title {
            color: #000000;
            text-align: center;
            font-size: 22px;
            font-weight: 700;
          }
          .text {
            text-align: center;
            color: #000000;
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            min-height: 80px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: $size-phablet-max) {
    .container {
      padding-bottom: 0px !important;
    }
    .container,
    .content {
      flex-direction: column !important;
      align-items: center;
      .features {
        flex-direction: column !important;
        align-items: center !important;
      }
    }
  }
</style>
