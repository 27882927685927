import { toast } from "@zerodevx/svelte-toast";

export const showToast = (message, isError = false, options) => {
  toast.push(message, {
    theme: {
      "--toastBackground": isError ? "#ca5b53" : "#fff5ba",
      "--toastBarBackground": isError ? "#8c4a46" : "#e6d097",
      "--toastColor": isError ? "#ffffff" : "#000000",
    },
    ...options,
  });
};

export const showSuccessToast = (message, options) => {
  showToast(message, false, options);
};

export const showErrorToast = (message, options) => {
  showToast(message, true, options);
};
