<script>
  import PageSection from "components/widgets/PageSection.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";

  let features = [
    {
      text: "Tokens Backed 1-1 With Precious Metals",
      imageUrl:
        "../images/icons/gold-backed-savings-return-to-the-gold-standard.svg",
    },
    {
      text: "Low-Cost Global Payments",
      imageUrl: "../images/icons/low-fees-own-gold-with-low-fees.svg",
    },
    {
      text: "24/7 Trading Even When the Market is Closed",
      imageUrl: "../images/icons/24-hr-trading-gold-to-diversify-wealth.svg",
    },
    {
      text: "Faster Processing Times",
      cssClass: "wide-image",
      imageUrl:
        "../images/icons/faster-processing-times-how-to-buy-gold-digitally.svg",
    },
    {
      text: "24-H Cash Liquidity & On-Demand Delivery",
      imageUrl:
        "../images/icons/liquidity-how-to-buy-gold-with-no-storage-fees.svg",
    },
    {
      text: "Extreme Divisibility & Portability",
      imageUrl: "../images/icons/divisibility-digital-gold-blockchain.svg",
    },
  ];
</script>

<PageSection light>
  <div class="container">
    <div class="content">
      <div class="heading">
        <Heading3
          ><span class="heading-text">
            We Leverage the World’s Most Efficient & Secure Financial Technology
            to Provide:
          </span></Heading3
        >
      </div>
      <div class="columns">
        <div class="column image-column">
          <img src="../../images/LQN/LQN_phone.webp" class="image" />
        </div>
        <div class="column">
          <div class="features">
            {#each features as feature (feature)}
              <div class="feature">
                <div class="icon">
                  <img
                    class={feature.cssClass ?? ""}
                    src={feature.imageUrl}
                    alt={feature.text}
                  />
                </div>
                <div class="text">{feature.text}</div>
              </div>
            {/each}
          </div>
        </div>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";
  .container {
    max-width: $size-laptop-max;
    height: 100%;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 128px;
    display: flex;

    @media only screen and (max-width: $buy-sell-stack-breakpoint) {
      padding-bottom: 0px;
    }

    .columns {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    .image-column {
      max-width: 288px;
      margin-right: 64px;

      @media only screen and (max-width: $size-phablet-max) {
        margin-right: 0px;
        max-width: 192px;
      }
    }

    .content {
      padding: 0px 48px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 50px;
      display: flex;
      max-width: 1200px;

      .heading {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 32px;
        display: flex;
        text-align: center;

        .heading-text {
          &,
          span {
            display: flex;
            flex-wrap: wrap;
            color: black;
            column-gap: 8px;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            max-width: calc(100vw - 16px);
            &.highlighted-text {
              color: #d9b44f;
            }
          }
        }
        .heading-text {
          max-width: 950px;
        }

        .logos {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 128px;
        }
      }

      .features {
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
        max-width: 768px;
        row-gap: 36px;
        column-gap: 32px;
        display: flex;

        .feature {
          flex-shrink: 0;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          display: flex;
          width: 240px;

          .icon {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .wide-image {
              margin-left: -45px;
            }
            &,
            img {
              height: 90px;
            }
          }
          .text {
            text-align: center;
            color: #000000;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            min-height: 80px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: $size-phablet-max) {
    .container {
      padding-bottom: 0px !important;
    }
    .container,
    .content {
      flex-direction: column !important;
      align-items: center;
      .logos {
        flex-direction: column !important;
        align-items: center !important;
        gap: 24px !important;
      }
      .features {
        flex-direction: column !important;
        align-items: center !important;
      }
    }
  }
</style>
