<script>
  import Icon from "components/elements/Icon.svelte";
  import Anchor from "components/elements/Anchor.svelte";
  import {
    TELEGRAM_URL,
    TWITTER_URL,
    LINKEDIN_URL,
    CONTACT_EMAIL,
    FACEBOOK_URL,
    INSTAGRAM_URL,
  } from "utils/menu.js";
</script>

<div class="social {$$props.class || ''}">
  <Anchor href={TWITTER_URL} class="social-icon"
    ><Icon name="social-x" /></Anchor
  >
  <Anchor href={LINKEDIN_URL} class="social-icon"
    ><Icon name="social-linkedin" /></Anchor
  >
  <Anchor href={TELEGRAM_URL} class="social-icon"
    ><Icon name="social-telegram" /></Anchor
  >
  <Anchor href={FACEBOOK_URL} class="social-icon"
    ><Icon name="social-facebook" /></Anchor
  >
  <!-- <Anchor href={INSTAGRAM_URL} class="social-icon"
    ><Icon name="social-instagram" /></Anchor
  > -->
  <!-- <Anchor href={CONTACT_EMAIL} class="social-icon"
    ><Icon name="social-mail" /></Anchor
  > -->
</div>

<style lang="scss">
  @import "../../styles/global.scss";

  :global(.social-icon:hover svg g path) {
    fill-opacity: 0.6;
  }

  .social {
    margin-top: 0px;
    gap: 16px;
    height: 20px;
    display: flex;
    align-items: center;

    :global(div) {
      height: 20px;
    }
  }
</style>
