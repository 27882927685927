<script>
  import PageSection from "components/widgets/PageSection.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import Btn from "components/elements/Btn.svelte";

  let features = [
    {
      text: `Liquid Noble owns a precious metals reserve in Australia, managed and stored by our trusted vaulting partners.`,
      imageUrl: "../images/process_vault.webp",
    },
    {
      text: `Partnered with Tokenized, we  provide our clients the ability to purchase a portion of our precious metals reserve, kept free of storage fees in their digital wallet.`,
      imageUrl: "../images/process_tokenized.webp",
    },
    {
      text: `This way, our users can get all of the benefits of gold, but in a practical and affordable way that lets them spend it like their normal money.`,
      imageUrl: "../images/process_own.webp",
    },
    {
      text: `Our users can then choose to keep, sell, transact in, or take physical delivery of their tokenized precious metals, like NobleGold, at anytime.`,
      imageUrl: "../images/process_transact.webp",
    },
  ];
</script>

<PageSection light>
  <div class="container">
    <div class="content">
      <div class="heading">
        <Heading3
          ><span class="heading-text"> Here’s How it Works: </span>
        </Heading3>
      </div>
      <div class="features">
        {#each features as feature (feature)}
          <div class="feature">
            <div class="icon">
              <img src={feature.imageUrl} alt={feature.title} />
            </div>
            <div class="text">{feature.text}</div>
          </div>
        {/each}
      </div>
      <div class="buttons">
        <Btn gold href="/docs/most-common">Learn More</Btn>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";
  .container {
    width: $size-laptop-max;
    height: 100%;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 128px;
    display: flex;

    @media only screen and (max-width: $buy-sell-stack-breakpoint) {
      padding-bottom: 0px;
    }

    .content {
      padding: 0px 48px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 50px;
      display: flex;
      max-width: 1200px;

      .heading {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 32px;
        display: flex;
        text-align: center;

        .heading-text {
          &,
          span {
            display: flex;
            flex-wrap: wrap;
            color: black;
            column-gap: 5px;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            max-width: calc(100vw - 16px);
            &.highlighted-text {
              color: #d9b44f;
            }
          }
        }
      }

      .features {
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        width: 100vw;
        @media only screen and (max-width: $size-ultra-wide) {
          max-width: 1200px;
        }
        row-gap: 60px;
        column-gap: 60px;
        display: flex;
        padding: 0 32px 32px 32px;

        .feature {
          flex-shrink: 0;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          display: flex;
          row-gap: 25px;
          width: 100%;
          max-width: 361px;
          min-height: 478px;
          padding: 26px 33px;

          border-radius: 14px;
          background: var(--White, #fff);
          box-shadow: 4px 4px 15.3px 5px rgba(0, 0, 0, 0.25);

          .icon {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &,
            img {
              width: 100%;
              border-radius: 14px;
            }
          }
          .text {
            text-align: center;
            color: #000000;
            font-size: 20px;
            font-weight: 400;
            line-height: 34px;
            min-height: 80px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: $size-phablet-max) {
    .container {
      padding-bottom: 0px !important;
    }
    .container,
    .content {
      flex-direction: column !important;
      align-items: center;
      .features {
        flex-direction: column !important;
        align-items: center !important;
      }
    }
  }
</style>
