<script>
  import { onMount } from "svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import Eyebrow from "components/elements/headings/Eyebrow.svelte";
  import Body from "components/elements/headings/Body.svelte";
  import Heading2 from "components/elements/aliases/Heading2.svelte";
  import Btn from "components/elements/Btn.svelte";
  import { isReferralPortal } from "./utils/menu";
  import { getBranchData } from "./modules/Branch.svelte";
  import { APP_URL } from "utils/menu.js";
  import { apiPostJSON } from "utils/api";

  let referralCode = "";
  let branchData = null;
  let submitting = false;
  let isCodeValid = false;
  let isCodeChecked = false;

  // Get location path
  const path = window.location?.pathname;
  if (path && path.includes("/")) {
    referralCode = path.split("/").pop();
  }

  async function initCachedBranch() {
    try {
      branchData = await getBranchData();
    } catch (e) {
      branchData = null;
      console.log("Branch retrieval failed: ", e);
    }

    // If the code is not provided via URL, attempt to use the branch version
    if (branchData?.referral_code && !referralCode) {
      referralCode = branchData.referral_code;
    }
  }

  async function initReferralCode() {
    if (!referralCode) {
      return;
    }
    checkReferralCode();
  }

  function getAppUrl(referral) {
    return `${APP_URL}?signup=true${referral ? `&referral=${referral}` : ""}`;
  }

  function resetForm() {
    referralCode = "";
  }

  async function checkReferralCode() {
    try {
      const response = await apiPostJSON("/user/referral/verify/", {
        referral: referralCode,
      });

      isCodeValid = !!response.valid;
    } catch (e) {
      console.log("Referral error: ", e);
    }

    isCodeChecked = true;
  }

  async function handleSubmit() {
    if (submitting) {
      return;
    }

    submitting = true;

    await checkReferralCode();

    const url = getAppUrl(isCodeValid ? referralCode : null);
    if (!isReferralPortal() || isCodeValid) {
      // Valid referral code mandatory only on the referral website liquid.vip
      resetForm();
      window.location = url;
    }
    submitting = false;
  }

  onMount(() => {
    initCachedBranch();
    initReferralCode();
  });
</script>

<PageSection>
  <div class="login-form columns" id="affiliate-login">
    <div class="column">
      <div class="heading">
        <Eyebrow small>Liquid Noble Account &amp; Wallet</Eyebrow>
        <Heading2>Affiliate referral</Heading2>
      </div>
      <div class="left">
        <Body class="contact-text">
          You were invited by an affiliate to join Liquid Noble. Registration is
          quick and simple, giving you access to all our features.
        </Body>
        <div class="form-container">
          <form on:submit|preventDefault={handleSubmit}>
            <div>
              <Body class="referral-text">
                Please ensure that your affiliate referral code is entered
                below:
              </Body>
              <div
                class="form-group {isCodeChecked &&
                  ((isCodeValid && 'valid') || (!isCodeValid && 'invalid'))}"
              >
                <input
                  type="text"
                  placeholder="Referral code"
                  maxlength="64"
                  bind:value={referralCode}
                  on:input={() => {
                    isCodeChecked = false;
                  }}
                  required
                />
              </div>
              {#if isCodeChecked && !isCodeValid}
                <span class="validation-text">
                  This is not a valid referral code.
                </span>
              {/if}
            </div>

            <Btn buttonType="submit" class="tokenized-button" gold wide>
              Register for Liquid Noble
            </Btn>
          </form>
        </div>
        <span class="toc-row">
          By signing in or creating an account, you agree to the
          <a
            target="_blank"
            rel="noreferrer"
            href="https://liquidnoble.com/legal/terms-and-conditions"
          >
            Terms & Conditions,
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://liquidnoble.com/legal/privacy-policy"
          >
            Privacy Policy,
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://liquidnoble.com/legal/aml-ctf-policy"
          >
            AML/CTF Policy
          </a>
          and
          <a
            target="_blank"
            rel="noreferrer"
            href="https://liquidnoble.com/legal/cookie-policy"
          >
            Cookie Policy
          </a>
        </span>
      </div>
    </div>
    <div class="column image-column">
      <img src="/images/dashboard_gold.webp" class="image" />
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";
  .login-form {
    margin-top: 24px;
    margin-bottom: 120px;
    border-radius: 16px;
    padding-right: 24px;
    padding-left: 24px;
    :global(.referral-text) {
      margin-bottom: 12px;
      font-size: 13px;
      color: var(--color-N75);
    }
  }
  .toc-row {
    display: block;
    margin-top: 8px;
    font-size: 12px;
    a {
      font-size: 12px;
      color: var(--color-P80);
    }
  }

  :global(.tokenized-button) {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;

    border-radius: 6px;
    color: var(--color-N80);

    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 0 solid transparent;
    box-shadow:
      0 1px 1px 0 var(--color-TN10),
      0 0 0 1px var(--color-N20);

    &:hover {
      filter: brightness(100%) !important;
      background: rgba(235, 237, 238, 0.25);
    }
    &:active {
      background: rgba(235, 237, 238, 0.5);
    }
  }
  .tokenized-logo {
    height: 24px;
    width: 24px;
    img {
      border-radius: 4px;
    }
  }
  .columns {
    align-items: center;
    width: 100%;
  }
  .column {
    gap: 48px;
    max-width: 544px;
    &.image-column {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      @media screen and (min-width: $size-phablet-max) {
        padding-left: 40px;
      }
      @media screen and (max-width: $size-phablet-max) {
        align-items: center;
      }
      img {
        max-width: 510px;
        @media screen and (max-width: $size-phone-max) {
          display: none;
        }
      }
    }
    .heading {
      display: flex;
      flex-direction: column;
      gap: 12px;
      :global(.heading2) {
        color: $green;
      }
    }
    .form-container form {
      margin-top: 24px;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 24px;
      display: flex;
    }

    .form-group {
      width: 100%;
      padding: 10px;
      background: white;
      border-radius: 4px;
      overflow: hidden;
      border: 0.5px var(--color-N40) solid;
      color: var(--color-N80);
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      display: flex;

      input[type="text"] {
        width: 100%;
        padding: 8px;
        font-size: 16px;
        border: none;
        background-color: transparent;
        resize: none;
      }

      &.invalid {
        border: 0.5px rgba(250, 0, 0, 0.8) solid;
      }
      &.valid {
        border: 0.5px var(--color-T55) solid;
      }
    }
    .validation-text {
      color: rgba(250, 0, 0, 0.8);
      margin-top: 8px;
      font-size: 12px;
    }
    .image {
      max-width: 360px;
    }
  }
</style>
