/**
 * Documentation related configuration
 */

export const LEGAL_DOCS_SOURCE = "/legal/";
export const GENERIC_DOCS_SOURCE = "/docs/";
export const TOC_YAML = "config/toc-docs.yaml";
export const LEGAL_VERSION = "v1.0.1";
export const GENERIC_DOCS_VERSION = "v1.0.1";

// Convenience method for relative retrieval of the protocol source files
export const documentFetch = (file, baseUrl) => fetch(`${baseUrl}${file}`);

// Convenience method for retrieving the TOC file
export const fetchTOC = (baseUrl) => documentFetch(TOC_YAML, baseUrl);

// Convert path component into an .md source file name
export const getPageFilename = (page) => {
  if (!page) {
    return null;
  }
  if (page.endsWith("/")) {
    page = page.slice(0, -1);
  }
  return `${page.replace("/", "-")}.md`;
};

// Convert path to a relative filename and retrieve its contents
export const fetchPageData = (page, baseUrl) => {
  const pageFilename = getPageFilename(page);
  return documentFetch(pageFilename, baseUrl);
};
