/** Header menu contents */

export const PRODUCTION_HOSTNAMES = ["liquidnoble.com"];

export const REGISTRATION_HOSTNAMES = [
  "register.liquidnoble.com",
  "get.lqn.app",
];
export const REGISTRATION_URL = "https://liquidnoble.com/register/";
export const REFERRAL_PORTAL_HOSTNAME = "liquid.vip";
// Referral hostnames are used to provide affiliate-specific pages configured in the API
export const REFERRAL_HOSTNAMES = ["dev.lqn.app", "lqn.app", "liquid.vip"];

export const REFERRAL_REGEXES = [
  /^(\w+\.)+referral-dev\.liquidnoble\.com$/,
  /^(\w+\.)+liquid\.vip$/,
];

// Dynamically replaced by rollup.config.js - do not change
export const APP_URL = "WEB_APP_URL";

// External resource URLs
export const LEGAL_DOCS_URL = "/legal/";
export const GENERIC_DOCS_URL = "/docs/";
export const PRIVACY_POLICY = "/legal/privacy";
export const TERMS_AND_CONDITIONS = "/legal/terms";
export const SIGN_IN_LINK = "/register/";
export const CONTACT_EMAIL = "mailto:hello@liquidnoble.com";

// Social links

export const TELEGRAM_URL = "https://t.me/+5GXs7ZBlhi1lMWNk";
export const LINKEDIN_URL = "https://linkedin.com/company/liquid-noble";
export const TWITTER_URL = "https://twitter.com/LiquidNobleGold";
export const FACEBOOK_URL = "https://www.facebook.com/LiquidNoble";
export const INSTAGRAM_URL = "https://www.instagram.com/liquidnoblegold/";

// Referral portal enforces referral code presence
export const isReferralPortal = () => {
  return window.location?.hostname === REFERRAL_PORTAL_HOSTNAME;
};

export const BUTTON_DATA = [
  { label: "FAQ", link: "/docs/most-common" },
  { label: "About", link: "/docs/overview" },
  { label: "Blog", link: "/blog" },
  { label: "Contact Us", link: "/contact-us/" },
];
if (!isReferralPortal()) {
  BUTTON_DATA.push({ label: "Sign In", link: APP_URL });
}

export const isDynamicReferralPage = () => {
  const hostname = window.location?.hostname;
  if (!hostname) {
    return false;
  }
  if (REFERRAL_HOSTNAMES.includes(hostname)) {
    return true;
  }

  for (const regex of REFERRAL_REGEXES) {
    if (regex.test(hostname)) {
      return true;
    }
  }
  return false;
};

/**
 * Could be API-driven or taken from a JSON file.
 * Hardcoded for now.
 */
export const MENU_CONTENTS = {
  0: [
    {
      icon: "shield",
      title: "About Liquid Noble",
      description: "Information about our digital investment currency",
      link: "/About/",
    },
  ],
  1: [
    {
      icon: "shield",
      title: "Our Team",
      description: "Learn about our company",
      link: "/platform/about/",
    },
  ],
};
