<script>
  import { onMount } from "svelte";
  import Btn from "components/elements/Btn.svelte";
  import PageSection from "components/widgets/PageSection.svelte";
  import Select from "components/elements/Select.svelte";

  import {
    initCachedBranch,
    onSubmitContactRequest,
  } from "./modules/Contact.svelte";

  let fullName = "";
  let email = "";
  let message = "";
  let messageReceived = false;
  let selectedSource = "";

  const SOURCE_OPTIONS = [
    { value: "affiliate", label: "Affiliate Website" },
    { value: "google", label: "Google" },
    { value: "social", label: "Social Media" },
    { value: "article", label: "Online Article" },
    { value: "friend", label: "Friend" },
    { value: "other", label: "Other" },
  ];

  function resetForm() {
    fullName = "";
    email = "";
    message = "";
    selectedSource = "";
  }

  async function handleSubmit() {
    messageReceived = await onSubmitContactRequest({
      email,
      fullName,
      message: message || "--- Contact form submission ---",
      source: selectedSource,
    });
    if (messageReceived) {
      resetForm();
    }
  }

  onMount(() => initCachedBranch());
</script>

<PageSection purple>
  <div class="contact-form">
    <div class="title">Contact Us</div>
    <div class="subtitle">
      Have questions about our platform, have a press inquiry, or need help
      registering? Fill out the form below and we’ll be in touch soon.
    </div>
    <div class="form-frame">
      <div class="form-row">
        <div class="form-left">
          <div class="form-container">
            <form on:submit|preventDefault={handleSubmit}>
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Name"
                  bind:value={fullName}
                  required
                />
              </div>
              <div class="form-group">
                <input
                  type="email"
                  placeholder="E-mail"
                  bind:value={email}
                  required
                />
              </div>
              <div class="form-group">
                <textarea required placeholder="Message" bind:value={message} />
              </div>
              <Select
                options={SOURCE_OPTIONS}
                bind:value={selectedSource}
                defaultOption="How did you find us?"
              />

              {#if messageReceived}
                <div class="form-group message-received">
                  Thank you for your enquiry! We will get back to you soon.
                </div>
              {:else}
                <Btn
                  gold
                  buttonType="submit"
                  class="submit-button-stretch"
                  linkClass="submit-button-stretch">Send</Btn
                >
              {/if}
            </form>
          </div>
        </div>
        <div class="form-right">
          <img src="../../images/liquid_noble_map.svg" alt="Liquid Noble Map" />
        </div>
      </div>
      <div class="form-row">
        <div class="contact-cell">
          <img src="../../images/icons/phone.svg" alt="Phone Number" />
          <div class="vertical">
            <div>Phone</div>
            <div>
              <a href="tel:+61 7 3435 1507">+61 (0) 7 3435 1507</a>
            </div>
          </div>
        </div>

        <div class="contact-cell">
          <img src="../../images/icons/email.svg" alt="Phone Number" />
          <div class="vertical">
            <div>Email</div>
            <div>
              <a href="mailto:hello@liquidnoble.com">hello@liquidnoble.com </a>
            </div>
          </div>
        </div>

        <div class="contact-cell">
          <img src="../../images/icons/location.svg" alt="Phone Number" />
          <div class="vertical">
            <div>Address</div>
            <div>
              <a
                href="https://maps.app.goo.gl/SnfTYpitTRf5s4nt7"
                target="_blank"
              >
                Suite #32, 42 Manilla St, East Brisbane, Queensland 4169,
                Australia
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";
  :global(.submit-button-stretch) {
    width: 100%;
    height: 50px;
    border-radius: 0px !important;
  }

  .contact-form {
    margin-top: 48px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 92px 156px 82px 156px;
    @media only screen and (max-width: 1452px) {
      padding: 92px 24px 82px 24px;
    }
    @media only screen and (max-width: $size-phone-max) {
      padding: 92px 8px 82px 8px;
    }
  }

  .title {
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: normal;

    @media only screen and (max-width: $size-phone-max) {
      font-size: 32px;
    }
  }
  .subtitle {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    max-width: 901px;
    margin-top: 18px;

    @media only screen and (max-width: $size-phone-max) {
      font-size: 16px;
      line-height: 32px;
    }
  }
  .form-frame {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 54px;
    border-radius: 12px;
    background: var(--White, #fff);
    box-shadow: 0px 141px 200px -80px rgba(25, 58, 75, 0.3);
    padding: 88px 140px 44px 140px;

    @media only screen and (max-width: $size-tablet-min) {
      padding: 32px 32px 44px 32px;
    }
    @media only screen and (max-width: $size-phone-small) {
      padding: 32px 8px 44px 8px;
    }
  }
  .form-row {
    width: 100%;
    display: flex;
    column-gap: 50px;
    row-gap: 32px;
    flex-wrap: wrap;
  }
  .form-row + .form-row {
    margin-top: 60px;
  }
  .contact-cell {
    display: flex;
    color: #000000;
    align-items: center;
    column-gap: 15px;
    flex-shrink: 0;
    &,
    a {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      color: #000000;
    }
    .vertical {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      flex-shrink: 0;

      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      @media only screen and (max-width: $size-phone-max) {
        flex-wrap: wrap;
        max-width: 226px;
      }

      > div:first-child {
        font-weight: bold;
      }
    }
  }
  .form-left {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    min-width: 250px;
  }
  .form-right {
    width: 100%;
    max-width: 544px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;

    @media only screen and (max-width: 1189px) {
      max-width: 100%;
    }
  }

  .form-container form {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    display: flex;
  }

  .form-group {
    width: 100%;
    background: white;
    overflow: hidden;
    border: 0.5px #828282 solid;
    color: var(--color-N80);
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    display: flex;

    div {
      color: black;
      font-size: 20px;
      font-weight: 400;
      word-wrap: break-word;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      width: 100%;
      padding: 12px 20px;
      border: none;
      background-color: transparent;
      resize: none;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.14px;
    }
    textarea {
      min-height: 136px;
    }

    input[type="checkbox"] {
      margin-right: 8px;
    }
    input[type="checkbox"]:checked {
      background-color: green !important;
    }

    span {
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 24px;
      letter-spacing: 0.28;
      word-wrap: break-word;
    }
  }

  .message-received {
    color: #000000;
    font-size: 16px;
    background-color: $gold-light;
    padding: 10px 20px;
    height: 50px;
  }

  .submit-button {
    height: 40px;
    padding: 8px 16px;
    background: linear-gradient(
      107deg,
      #937131 3%,
      #eed680 26%,
      #cdb460 49%,
      #978348 74%,
      #fef7b3 100%
    );
    border-radius: 6px;
    border: 1px #fff5ba solid;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #181818;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 24px;
    letter-spacing: 0.28;
    word-wrap: break-word;
    &:hover {
      filter: brightness(1.25);
    }
  }
</style>
