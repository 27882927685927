<script>
  export let ref = undefined;
</script>

<svg
  {ref}
  width="21"
  height="21"
  viewBox="0 0 21 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="Social icon">
    <path
      id="Vector"
      d="M2.78596 9.33568C7.70726 7.19154 10.9889 5.778 12.6309 5.09504C17.3191 3.14507 18.2932 2.80633 18.9282 2.79503C19.0678 2.79269 19.3801 2.8273 19.5823 2.99142C19.7531 3.13 19.8001 3.3172 19.8226 3.44859C19.8451 3.57998 19.8731 3.87929 19.8508 4.11316C19.5968 6.78253 18.4975 13.2604 17.9382 16.2501C17.7016 17.5152 17.2356 17.9394 16.7845 17.9809C15.8042 18.0711 15.0598 17.333 14.1102 16.7106C12.6245 15.7366 11.7851 15.1303 10.3429 14.1799C8.67612 13.0816 9.75659 12.4779 10.7065 11.4913C10.955 11.2331 15.2745 7.3043 15.3581 6.9479C15.3685 6.90332 15.3782 6.73717 15.2795 6.64943C15.1808 6.5617 15.0351 6.5917 14.93 6.61556C14.781 6.64938 12.4074 8.21823 7.80926 11.3221C7.13553 11.7847 6.52528 12.0101 5.97852 11.9983C5.37577 11.9853 4.2163 11.6575 3.35436 11.3773C2.29716 11.0337 1.45691 10.852 1.53008 10.2684C1.56819 9.96436 1.98681 9.65347 2.78596 9.33568Z"
      fill="white"
    />
  </g>
</svg>
