<script>
  import Icon from "components/elements/Icon";

  export let value;
  export let options = [];
  export let className = "";
  export let chevronIcon = "chevron-down";
  export let defaultOption = "";
</script>

<div class="select-wrapper">
  <div class={`dropdown ${className}`}>
    {#each options as option}
      {#if option === value || (option.value !== undefined && option.value === value)}
        {option.label}
      {/if}
    {/each}
    <Icon name={chevronIcon} ref="icon-svg" />
  </div>

  <select class={`dropdown ${className}`} bind:value>
    {#if !value && !!defaultOption}
      <option {value} disabled selected hidden>
        {defaultOption}
      </option>
    {/if}
    {#each options as option}
      <option value={option.value !== undefined ? option.value : option}>
        {option.label}
      </option>
    {/each}
  </select>
</div>

<style lang="scss">
  @import "../../styles/global.scss";

  .select-wrapper {
    position: relative;
    height: 48px;
    width: 100%;
  }

  .default-placeholder {
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--black, var(--Black, #000));
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.14px;
    margin-left: 20px;
    pointer-events: none;
  }

  .dropdown {
    display: flex;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    width: 100%;
    height: 48px;
    font-size: 15px;
    border-color: #828282;
    border-style: solid;
    border-width: 0.5px;
    color: $green;
    cursor: pointer;
    z-index: 0;

    :global([ref="icon-svg"] > path) {
      stroke: $green;
    }
  }
  select.dropdown {
    position: absolute;
    z-index: 1;
  }
</style>
