<script>
  import PageSection from "components/widgets/PageSection.svelte";
  import PageLayout from "components/PageLayout.svelte";
  import {
    TELEGRAM_URL,
    TWITTER_URL,
    LINKEDIN_URL,
    INSTAGRAM_URL,
  } from "utils/menu.js";
</script>

<PageLayout blackHeader>
  <PageSection heroPurple>
    <div class="thank-you">
      <div class="columns">
        <div class="column text-column">
          <div class="thank-you-image">
            <img src="../../images/thank_you.svg" />
          </div>
          <div class="subtitle">
            Your free copy of the Investor Guide is on its way!
          </div>
          <div>
            We look forward to welcoming you to our platform. In the meantime,
            if you would like to know more about Liquid Noble, precious metals,
            or blockchain innovation, follow us on our social media channels
            below!
          </div>
          <div class="social-links">
            <a href={TWITTER_URL} target="_blank"
              ><img src="../../images/social_x.svg" /></a
            >
            <a href={LINKEDIN_URL} target="_blank"
              ><img src="../../images/social_linkedin.svg" /></a
            >
            <a href={TELEGRAM_URL} target="_blank"
              ><img src="../../images/social_telegram.svg" /></a
            >
            <a href={INSTAGRAM_URL} target="_blank"
              ><img src="../../images/social_instagram.svg" /></a
            >
          </div>
        </div>
      </div>
    </div>
  </PageSection>
</PageLayout>

<style lang="scss">
  @import "../../../styles/global.scss";

  .thank-you {
    @extend .generic-section;
    max-width: $size-laptop-max;
    gap: 128px;
    min-height: 60vh;
    padding-bottom: 64px;
    .thank-you-image {
      display: flex;
      justify-content: center;
      width: 100%;

      @media only screen and (max-width: $size-tablet-max) {
        img {
          width: 100%;
          max-width: calc(100vw - 96px);
        }
      }
    }
    .social-links {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 48px;
      row-gap: 24px;
      margin-top: 32px;

      img {
        width: 48px;
        height: 48px;
      }
    }
    .columns {
      position: relative;
      justify-content: center;
      column-gap: 52px;
      margin: 0px;
      padding: 0px 48px;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
    .highlighted-text {
      color: #d9b44f;
    }
    .subtitle {
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      width: 100%;
      text-align: center;
      margin-bottom: 8px;
    }
    .column {
      gap: 24px;
      z-index: 1;
      &.text-column {
        width: 664px;
        max-width: 827px;
        padding-top: 196px;
        margin-left: 48px;
        text-align: center;

        @media only screen and (max-width: $size-phablet-min) {
          margin-left: 0px;
        }

        @media only screen and (max-width: $size-phablet-min) {
          padding-top: 124px;
        }
      }
    }
    @media only screen and (min-width: $size-phablet-min) and (max-width: $size-tablet-max) {
      .columns {
        text-align: center;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-start;
        .column {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .mobile {
          display: block;
        }
        .desktop {
          display: none;
        }
      }
    }
    @media only screen and (max-width: $size-phablet-min) {
      gap: 80px;

      .columns {
        flex-direction: column-reverse;
        text-align: center;
        .column {
          width: 100%;
          gap: 24px;
          &.desktop {
            display: none;
          }
          .mobile {
            display: block;
          }
        }
      }
    }
  }
</style>
