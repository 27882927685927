<script>
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import { SIGN_IN_LINK } from "utils/menu";
  import { onMount } from "svelte";

  let collapsedCards = [];
  export let assets = [];
  export let title = "";
  export let imageRight;
  export let imageRightAlt = "";

  function toggleCard(index) {
    collapsedCards[index] = collapsedCards[index] ? false : true;
    collapsedCards = [...collapsedCards];
  }

  onMount(() => {
    for (let i = 1; i < assets.length; i++) {
      toggleCard(i);
    }
  });
</script>

<div class="assets">
  <div class="columns">
    <div class="heading">
      <Heading3 dark>{title}</Heading3>
    </div>
  </div>
  <div class="columns">
    <div class="image-row">
      <div class="row">
        {#each assets as asset, index (asset.symbol)}
          <div class="card">
            <div
              class="card-header"
              on:keydown={() => toggleCard(index)}
              on:click={() => toggleCard(index)}
            >
              <div class="symbol-name">
                <div
                  class="icon"
                  style="border: 4px solid {asset.iconColor}; color:{asset.iconColor};"
                >
                  <div class="symbol" style="color:{asset.iconColor}">
                    {asset.symbol}
                  </div>
                </div>
                <div class="name phone">{asset.name}</div>
              </div>
              <div class="chevron">
                {#if collapsedCards[index] === true}
                  <img src="../images/chevron-down.svg" alt="Chevron Down" />
                {:else}
                  <img src="../images/chevron-up.svg" alt="Chevron Up" />
                {/if}
              </div>
            </div>
            <div class="card-body {collapsedCards[index] ? 'closed' : 'open'}">
              <div>
                <div class="name desktop">{asset.name}</div>
                <div class="description">{asset.description}</div>
              </div>
              <div class="button">
                <a href={SIGN_IN_LINK} class="button-text"
                  >{asset.buttonText}
                </a>
              </div>
            </div>
          </div>
        {/each}
      </div>
      {#if imageRight}
        <div class="image-right">
          <img src={imageRight} alt={imageRightAlt} />
        </div>
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  @import "../../styles/global.scss";
  .assets {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .columns {
      width: 100%;
      max-width: 1600px;
      height: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      display: flex;

      .heading {
        width: 100%;
        padding-bottom: 16px;
        display: flex;
        justify-content: center;
      }
      .image-row {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        column-gap: 64px;
        .image-right {
          position: relative;
          display: flex;
          @media only screen and (max-width: $size-laptop-min) {
            display: none;
          }
          align-items: center;
          &,
          img {
            width: 300px;
            height: auto;
          }
          img {
            position: absolute;
            margin-left: -20px;
            margin-top: -120px;
          }
        }
      }
      .row {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        .card {
          width: calc(50% - 16px);
          display: flex;
          padding: 24px 48px 24px 24px;
          align-items: flex-start;
          gap: 24px;
          flex-shrink: 0;
          flex-grow: 1;
          border-radius: 12px;
          background: var(--White, #fff);
          box-shadow:
            0px 0px 16px -8px rgba(0, 0, 0, 0.12),
            0px 13px 27px -5px rgba(0, 0, 0, 0.08);

          @media only screen and (max-width: $size-phablet-max) {
            min-height: 0px;
          }

          .name {
            display: flex;
            height: 56px;
            flex-direction: column;
            justify-content: center;
            color: $N90;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            @media only screen and (max-width: $size-phone-small) {
              font-size: 17px;
            }
          }
          .card-header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 56px;
            @media only screen and (max-width: $size-phablet-max) {
              max-width: 100%;
            }

            cursor: pointer;
            .symbol-name {
              display: flex;
              flex-direction: row;
              gap: 24px;
              align-items: center;

              .phone {
                display: none;
              }
              @media only screen and (max-width: $size-phablet-max) {
                .phone {
                  display: flex !important;
                }
              }
            }
            .chevron {
              display: none;
            }
          }
          .icon {
            padding: 6px;
            justify-content: center;
            align-items: center;
            display: flex;
            border-radius: 90px;
            font-size: 24px;
            font-weight: 600;
            .symbol {
              width: 36px;
              height: 36px;
              display: flex;
              align-content: center;
              justify-content: center;
              align-items: center;
            }
          }

          .card-body {
            width: 100%;
            height: 100%;
            justify-content: space-between;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;

            .description {
              color: $N65;
              font-size: 17px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
              padding-bottom: 16px;
            }
            .price {
              color: $N95;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              height: 28px;
            }
            .grain {
              color: $N45;
              font-size: 17px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            .button-text {
              display: flex;
              padding: 10px 0px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: var(--color-P70);
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;

              &:hover {
                color: var(--color-P60);
              }
            }

            @media only screen and (max-width: $size-phablet-max) {
              &.closed {
                display: none;
              }
              &.open {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: $size-phablet-max) {
    .row {
      flex-direction: column !important;
      .card {
        width: 100% !important;
        flex-direction: column !important;
        .chevron {
          display: flex !important;
        }
      }
    }
    .desktop {
      display: none !important;
    }
  }
</style>
