<script>
  import PageSection from "components/widgets/PageSection.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";

  let features = [
    {
      title: "Can Shield Against Inflation",
      text: `When the cost of everyday goods rise, the value of your money goes down. Gold historically moves in the opposite direction of the US dollar. This means that when the dollar gets weaker, gold often gets stronger. As a result, gold can be a powerful way to guard against the erosion of your purchasing power and inflation.`,
      imageUrl: "../images/features_inflation_shield.webp",
    },
    {
      title: "A Powerful Diversifier",
      text: `Owning gold can help spread risk and potentially improve your portfolio's overall stability. Since gold's price often moves in the opposite direction of other asset classes like stocks, its presence can help balance your portfolio and potentially mitigate losses during market downturns and periods of economic uncertainty.`,
      imageUrl: "../images/features_powerful_diversifier.webp",
    },
    {
      title: "Widely Considered a Safe-Haven",
      text: `Gold has a long history of being a safe haven asset, meaning it tends to hold or even increase its value during periods of economic uncertainty or market turmoil. This is due to several factors, including its limited supply, its physical durability, industrial applications, and its universal recognition as valuable.`,
      imageUrl: "../images/features_safe_haven.webp",
    },
    {
      title: "Can Help Hedge Geopolitical Risk",
      text: `Wars & crises can spook investors and cause stock markets to plunge. In uncertain times, investors have flocked to gold, driving up its price. Gold tends to be seen as a safe haven because it's a scarce resource with industrial demand and has intrinsic value. This can offset losses in other parts of your portfolio.`,
      imageUrl: "../images/features_risk_hedge.webp",
    },
  ];
</script>

<PageSection light>
  <div class="container">
    <div class="content">
      <div class="heading">
        <Heading3
          ><span class="heading-text">
            <span>Gold is a Treasured, Time-Tested Store of Value</span>
          </span>
        </Heading3>
        <span class="heading-text subheading">
          <span>
            As empires rise and fall, currencies crumble, <b
              >gold historically endures.</b
            >
            It's known as a timeless
            <b>hedge against economic uncertainty,</b> and can be a
            <b>stabilizing force</b> in your portfolio.
          </span>
        </span>
      </div>
      <div class="features">
        {#each features as feature (feature)}
          <div class="feature">
            <div class="icon">
              <img src={feature.imageUrl} alt={feature.title} />
            </div>
            <div class="title">{feature.title}</div>
            <div class="text">{feature.text}</div>
          </div>
        {/each}
      </div>
    </div>
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";
  .container {
    width: $size-laptop-max;
    height: 100%;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 128px;
    display: flex;

    @media only screen and (max-width: $buy-sell-stack-breakpoint) {
      padding-bottom: 0px;
    }

    .content {
      padding: 0px 48px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 50px;
      display: flex;
      max-width: 1200px;

      .heading {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 32px;
        display: flex;
        text-align: center;

        .heading-text {
          &,
          span {
            display: flex;
            flex-wrap: wrap;
            color: black;
            column-gap: 5px;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            max-width: calc(100vw - 16px);
            &.highlighted-text {
              color: #d9b44f;
            }
          }
        }
      }
      .subheading {
        color: #000000;
        font-size: 24px;
        line-height: 41px;
        padding: 0px 32px;
        text-align: center;
      }

      .features {
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        width: 100vw;
        @media only screen and (max-width: $size-ultra-wide) {
          max-width: 1200px;
        }
        row-gap: 60px;
        column-gap: 60px;
        display: flex;
        padding: 0 32px 32px 32px;

        .feature {
          flex-shrink: 0;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          display: flex;
          row-gap: 25px;
          width: 100%;
          max-width: 416px;
          padding: 26px;

          border-radius: 14px;
          background: var(--White, #fff);
          box-shadow: 4px 4px 15.3px 5px rgba(0, 0, 0, 0.25);

          .icon {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &,
            img {
              width: 100%;
              border-radius: 14px;
            }
          }
          .title {
            color: #000000;
            text-align: center;
            font-size: 22px;
            font-weight: 700;
          }
          .text {
            text-align: center;
            color: #000000;
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            min-height: 80px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: $size-phablet-max) {
    .container {
      padding-bottom: 0px !important;
    }
    .container,
    .content {
      flex-direction: column !important;
      align-items: center;
      .features {
        flex-direction: column !important;
        align-items: center !important;
      }
    }
  }
</style>
