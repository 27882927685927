<script>
  import {
    THEME_LIGHT,
    THEME_DARK,
    COLOR_SECONDARY,
    COLOR_PRIMARY,
    BUTTON_COLORS,
    BUTTON_THEMES,
    BUTTON_STATES,
    BUTTON_FILL,
    BUTTON_SIZES,
    STATE_ACTIVE,
    STATE_DISABLED,
    SIZE_MEDIUM,
    SIZE_LARGE,
    FILL_SOLID,
    FILL_LINK,
  } from "buttonStyles";
  import Icon from "components/elements/Icon";
  import Link from "components/elements/Link.svelte";

  // Props
  export let iconLeft = undefined;
  export let iconRight = undefined;
  export let dark = false;
  export let color = COLOR_PRIMARY;
  export let large = false;
  export let active = false;
  export let disabled = false;
  export let noFill = false;
  export let center = false;
  export let href = "";

  export let onClick = () => {
    if (href) {
      window.location = href;
    }
  };

  let clientWidth;
  let clientHeight;

  export const getClientWidth = () => clientWidth;
  export const getClientHeight = () => clientHeight;

  let theme = dark ? THEME_DARK : THEME_LIGHT;
  let size = large ? SIZE_LARGE : SIZE_MEDIUM;
  let fill = noFill ? FILL_LINK : FILL_SOLID;

  const colorMeta = BUTTON_COLORS[color];
  const themeMeta = BUTTON_THEMES[theme];
  const fillMeta = BUTTON_FILL[fill];
  const sizeMeta = BUTTON_SIZES[size];
  let stateCenter = center ? "center" : "";
  let stateMeta = null;
  let stateClass = "";

  const updateStateMeta = () => {
    stateMeta = null;
    if (active) {
      stateMeta = BUTTON_STATES[STATE_ACTIVE];
    }
    if (disabled) {
      stateMeta = BUTTON_STATES[STATE_DISABLED];
    }
  };
  $: {
    updateStateMeta(disabled, active);
    stateClass = stateMeta?.class || "";
  }
</script>

<Link dark scrollTop {color} {href}>
  <div
    class="button rounded {sizeMeta.class} {themeMeta.class} {colorMeta.class} {fillMeta.class} {stateCenter} {stateClass} {$$props.class ||
      ''}"
    on:click={onClick}
    bind:clientWidth
    bind:clientHeight
  >
    {#if !!iconLeft}
      <div class="icon">
        <Icon name={iconLeft} ref="icon-svg" />
      </div>
    {/if}
    <div class="content"><slot /></div>
    {#if !!iconRight}
      <div class="icon">
        <Icon name={iconRight} ref="icon-svg" />
      </div>
    {/if}
  </div>
</Link>

<style lang="scss">
  @import "../../styles/global.scss";

  .button {
    font-weight: 500;
    padding: 10px 16px;
    box-sizing: border-box;
    width: fit-content;
    cursor: pointer;
    &,
    .content {
      display: flex;
      justify-content: start;
      align-items: center;
      &.center {
        justify-content: center;
      }
    }
    .content + .icon {
      margin-left: 6px;
    }
    .icon {
      width: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      padding-top: 2px;
      + .content {
        margin-left: 6px;
      }
    }
    .content {
      display: block;
    }
  }
  .fill-link {
    padding: 10px 0px;
  }
  .size-medium {
    height: 36px;
    font-size: 16px;
    line-height: 24px;

    &.fill-link {
      min-width: 68px;
    }
  }
  .size-large {
    height: 44px;
    font-size: 18px;
    line-height: 27px;
  }
  .color-primary {
    color: var(--color-N95);
    :global([ref="icon-svg"] > path) {
      stroke: $gold;
    }
    &.active,
    &:active {
      background-color: $gold;
      &.theme-dark {
        background-color: $gold;
      }
    }
    &:hover {
      background-color: $gold;
      &.theme-dark {
        background-color: $gold;
      }
    }
    &.fill-link {
      color: var(--color-N10);
      background-color: transparent;
      :global([ref="icon-svg"] > path) {
        stroke: $gold;
      }
      &.theme-dark {
        color: $white;
        background-color: transparent;
        :global([ref="icon-svg"] > path) {
          stroke: $gold;
        }
      }
      &.active,
      &:active {
        color: $gold;
        :global([ref="icon-svg"] > path) {
          stroke: $gold;
        }
        &.theme-dark {
          color: $gold;
          :global([ref="icon-svg"] > path) {
            stroke: $gold;
          }
        }
      }
      &:hover {
        color: $gold;
        :global([ref="icon-svg"] > path) {
          stroke: $gold;
        }
        &.theme-dark {
          color: $gold;
          :global([ref="icon-svg"] > path) {
            stroke: $gold;
          }
        }
      }
    }
  }
  .color-attention {
    background-color: $gold;
    color: $gold;
    &.active,
    &:active {
      background-color: $gold;
    }
    &:hover {
      background-color: $gold;
    }
    &.fill-link {
      color: $gold;
      background-color: transparent;
      :global([ref="icon-svg"] > path) {
        stroke: $gold;
      }
      &.theme-dark {
        color: $gold;
        background-color: transparent;
        :global([ref="icon-svg"] > path) {
          stroke: $gold;
        }
      }
      &.active,
      &:active {
        color: $gold;
        :global([ref="icon-svg"] > path) {
          stroke: $gold;
        }
        &.theme-dark {
          color: $gold;
          :global([ref="icon-svg"] > path) {
            stroke: $gold;
          }
        }
      }
      &:hover {
        color: $gold;
        :global([ref="icon-svg"] > path) {
          stroke: $gold;
        }
        &.theme-dark {
          color: $gold;
          :global([ref="icon-svg"] > path) {
            stroke: $gold;
          }
        }
      }
    }
  }
  .color-secondary {
    color: $dark;
    :global([ref="icon-svg"] > path) {
      stroke: $gold;
    }
    &:hover {
      color: $gold;
      :global([ref="icon-svg"] > path) {
        stroke: $gold;
      }
    }
    &.active,
    &:active {
      color: $gold;
      :global([ref="icon-svg"] > path) {
        stroke: $gold;
      }
    }
    &.theme-dark {
      color: $gold;
      :global([ref="icon-svg"] > path) {
        stroke: $gold;
      }
      &:hover {
        color: $gold;
        :global([ref="icon-svg"] > path) {
          stroke: $gold;
        }
      }
      &.active,
      &:active {
        color: $gold;
        :global([ref="icon-svg"] > path) {
          stroke: $gold;
        }
      }
    }
  }
  .color-dark {
    color: var(--color-N80);
    :global([ref="icon-svg"] > path) {
      stroke: var(--color-N80);
    }
    &:hover {
      color: var(--color-N95);
      :global([ref="icon-svg"] > path) {
        stroke: var(--color-N95);
      }
    }
    &.active,
    &:active {
      color: var(--color-N95);
      :global([ref="icon-svg"] > path) {
        stroke: var(--color-N95);
      }
    }
  }
  .disabled {
    background-color: $gold;
    color: $bg-light;
    cursor: default;
    :global([ref="icon-svg"] > path) {
      stroke: $green;
    }
    &.active,
    &:active,
    &:hover {
      background-color: $gold;
    }
    &.theme-dark {
      background-color: $gold;
      &.active,
      &:active,
      &:hover {
        background-color: $gold;
      }
    }
  }
  .fill-link {
    &.disabled {
      background-color: transparent;
      color: $green;
      :global([ref="icon-svg"] > path) {
        stroke: $green;
      }
      &.theme-dark,
      &.theme-dark:hover {
        color: $green;
        :global([ref="icon-svg"] > path) {
          stroke: $green;
        }
      }
      &:hover {
        color: $green;
        background-color: transparent;
        :global([ref="icon-svg"] > path) {
          stroke: $green;
        }
      }
    }
  }
</style>
