<script>
  import PageSection from "components/widgets/PageSection.svelte";
  import AssetList from "components/elements/AssetList.svelte";

  const assets = [
    {
      symbol: "$",
      name: "NobleUSD",
      description:
        "The faster, safer, and more efficient way to send, spend, and exchange money around the world. NobleUSD is always redeemable for US dollars and provides anytime access to payments and financial services.",
      buttonText: "Buy NobleUSD",
      iconColor: "#64A7B9",
    },
    {
      symbol: "€",
      name: "NobleEURO",
      description:
        "Globally accessible and always on 24/7, NobleEURO enhances commerce across borders and time zones. Designed for stability, NobleEURO is 100% backed by euros and available for redemption on demand.",
      buttonText: "Buy NobleEURO",
      iconColor: "#64A7B9",
    },
    {
      symbol: "A$",
      name: "NobleAUD",
      description:
        "Offering 24/7 global accessibility and facilitating cross-border transactions, NobleAUD is Backed 1:1 by Australian dollars, is redeemable on demand, & prioritizes stability, making it a reliable tool for international trade and financial activities.",
      buttonText: "Buy NobleAUD",
      iconColor: "#64A7B9",
    },
  ];
</script>

<PageSection short>
  <!-- <AssetList
    title="And Fiat Credit Notes for Faster Payments"
    {assets}
    imageRight="../images/phone_dashboard.webp"
    imageRightAlt="Liquid Noble Dashboard"
  /> -->
  <div class="separator"></div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";
  .separator {
    height: 64px;
  }
</style>
