<script>
  export let ref = undefined;
</script>

<svg
  {ref}
  width="21"
  height="21"
  viewBox="0 0 21 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="Social icon" clip-path="url(#clip0_22_493)">
    <g id="Subtract">
      <path
        d="M0.795798 5.30116C0.908353 5.31515 1.01986 5.35248 1.12323 5.41489L10.6925 11.1925L20.2618 5.41489C20.3651 5.35252 20.4765 5.31521 20.589 5.30119C20.2211 3.86032 18.9145 2.79492 17.359 2.79492H4.02572C2.47027 2.79492 1.16366 3.86031 0.795798 5.30116Z"
        fill="white"
      />
      <path
        d="M20.6924 7.1018L11.1232 12.8793C10.8583 13.0392 10.5267 13.0392 10.2618 12.8793L0.692383 7.10165V14.4616C0.692383 16.3025 2.18477 17.7949 4.02572 17.7949H17.359C19.2 17.7949 20.6924 16.3025 20.6924 14.4616V7.1018Z"
        fill="white"
      />
    </g>
  </g>
  <defs>
    <clipPath id="clip0_22_493">
      <rect
        width="20"
        height="20"
        fill="white"
        transform="translate(0.692383 0.294922)"
      />
    </clipPath>
  </defs>
</svg>
