<script>
  import PageSection from "components/widgets/PageSection.svelte";
  import Heading3 from "components/elements/aliases/Heading3.svelte";
  import { onMount } from "svelte";

  let activeTopic = "Most common";

  let topics = {
    "Most common": [
      {
        question: "What is blockchain technology and how does it protect me?",
        answer: `Blockchain is a powerful tool for creating secure and transparent systems in many different areas. Imagine an online record book shared across many computers. Transactions are bundled together into blocks, and linked in a chain of information. However, to add a new block to the chain, everyone with a copy of the record book needs to agree on the information. This makes it very difficult to cheat or change anything once it's recorded.
        <br><br>
          For digital currencies, it’s a secure public record of who owns what digital coins. But it’s powerful security features have many other uses beyond money like:
        <ul>
          <li>Secure Record-Keeping: Imagine important documents like diplomas or medical records on a blockchain. No one can change them, making them super reliable.</li>
        <br>
          <li>Supply Chain Tracking: Ever wonder where your groceries come from? Blockchain can track products from farm to store, ensuring quality and avoiding fakes.</li>
        <br>
          <li>Voting Systems: Blockchain could make voting more secure by creating a tamper-proof record of votes.</li>
        <br>
          <li>Sharing Economy: Services like Airbnb or Uber could use blockchain to securely manage contracts and payments.</li>
        </ul>
          With all of this innovation, Liquid Noble is on the forefront by using the most efficient, secure, and scalable blockchain to record all of its transactions. This provides peace of mind and security to our users.
          `,
        isOpen: false,
      },
      {
        question: "How does Liquid Noble differ from cryptocurrencies?",
        answer:
          "Liquid Noble stands out by offering tangible precious metals linked to ownership certificates recorded on a blockchain, providing a level of stability and security that cryptocurrencies may lack. Additionally, the use of the BSV blockchain ensures transparency and efficiency in transactions, accompanied by low fees.",
        isOpen: false,
      },
      {
        question: "How can I buy precious metals on Liquid Noble?",
        answer:
          "Easily! Simply register, fund your account using AUD, and purchase your tokenized precious metals from the app in less than a minute. <a href='https://register.liquidnoble.com/' class='size-medium'>Click here</a> to open your account today!",
        isOpen: false,
      },
      {
        question:
          "Can I convert my digital assets back into physical precious metals?",
        answer: `
            Absolutely. With Liquid Noble, our customers have access to a wide range of cast bars and coins through our trusted precious metals providers. Your allocated NobleGold, and other tokenized precious metals can be converted to physical precious metals with the minimum weights as follows:
            <ul>
              <li>1 gram for gold</li>
              <li>1 oz for silver</li>
              <li>50 grams for platinum</li>
            </ul>
            Whether you’re looking to convert your NobleGold to a small ½ troy ounce bar, or hold a minted, collector’s gold coin like the Canadian Maple, or Australian Perth Kangaroo, we’ve got your precious metals needs covered.
            <br><br>
            Be advised, minted coins generally have a higher barring premium. The most cost-effective solution are cast bars, the smallest being ½ oz.
            <br><br>
            Buying and selling gold and silver bullion at the quoted market price via Liquid Noble incurs a small fee of .22%.
            <br><br>
            For a full breakdown of our prices and fees <a class='size-medium' href='/docs/fees'>click here</a>.
          `,
        isOpen: false,
      },
      // {
      //   question: "How do I know my gold is in the vaults?",
      //   answer: `
      //   Our trusted vaulting partner, ABC Bullion, is an industry leader in the Australia precious metals space. They have been serving clients for over 40 years and are committed to integrity and transparency with their customers. Their metals are audited 2 times per year and they publish their audit reports publicly <a href='https://abcbullion.com.au/storage-and-delivery/assurance-report' class='size-medium' target='_blank'>on their website</a> for review at any time.
      //     `,
      //   isOpen: false,
      // },
      {
        question: "Why should I trust Liquid Noble?",
        answer: `
        At Liquid Noble, trust is the cornerstone of our business. With 18 years in software development, we have partnered with leaders in both the precious metals industry and blockchain innovation to bring our users the most secure, and reliable digital gold on the market. 
        <br><br>
        To learn more about our strategic partners, and our commitment to transparency, <a href='/docs/overview' class='size-medium'>click here</a>.
        `,
        isOpen: false,
      },
      // {
      //   question: "How is Liquid Noble able to offer free storage on its gold?",
      //   answer: `
      //   Liquid Noble offers free storage through our strategic vaulting partner, ABC Bullion. They manage a reserve of physical gold in various forms and sizes, a portion of which is earmarked for Liquid Noble. Every ounce of gold in our reserve is backed by tangible, regularly audited assets, ensuring that each and every token is backed by the physical gold it represents.
      //   `,
      //   isOpen: false,
      // },
    ],
  };
  function setActiveTopic(topic) {
    activeTopic = topic;
  }

  onMount(() => {
    setActiveTopic(Object.keys(topics)[0]);
  });
</script>

<PageSection short>
  <div class="section">
    <div class="content-container">
      <Heading3 dark>Frequently asked questions</Heading3>
      <div class="content">
        <div class="topics">
          <!-- {#each Object.keys(topics) as topic}
            <div
              class="topic"
              class:active={activeTopic === topic}
              on:click={() => setActiveTopic(topic)}
            >
              <div class="topic-title">{topic}</div>
            </div>
          {/each}
        </div> -->
          <div class="questions">
            {#each topics[activeTopic] as question (question.question)}
              <div class="question">
                <div
                  class="question-title"
                  on:click={() => {
                    topics[activeTopic].forEach((otherQuestion) => {
                      if (otherQuestion !== question) {
                        otherQuestion.isOpen = false;
                      }
                    });

                    question.isOpen = !question.isOpen;
                  }}
                >
                  {question.question}
                  <div class="chevron">
                    {#if question.isOpen}
                      <img src="../images/chevron-up.svg" alt="Collapse" />
                    {:else}
                      <img src="../images/chevron-down.svg" alt="Expand" />
                    {/if}
                  </div>
                </div>
                {#if question.isOpen}
                  <div class="answer">{@html question.answer}</div>
                {/if}
              </div>
            {/each}
          </div>
        </div>
      </div>
    </div>
  </div></PageSection
>

<style lang="scss">
  @import "../../../../styles/global.scss";

  .section {
    width: 100%;
    height: 100%;
    padding-top: 44px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 128px;
  }

  .content-container {
    padding: 0px 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 64px;
    display: flex;
    width: 100%;
    max-width: $size-tablet-max;
    margin-bottom: 64px;
  }

  .content {
    max-width: $desktop-section-width;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    padding-bottom: 26px;

    .topics {
      flex-direction: row;
      gap: 32px;
      width: fit-content;
      .topic {
        padding-top: 16px;
        padding-bottom: 16px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        display: flex;
        min-width: 256px;
        cursor: pointer;
        width: fit-content;
        color: var(--color-N55);
        &.active,
        &:hover {
          color: var(--color-N95);
        }
        &.active::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 4px;
          height: 36px;
          background-color: var(--color-P55);
          transform: translate(-24px, -6px);
          border-radius: 2px;
          z-index: 1;
        }

        .topic-title {
          font-size: 20px;
          font-weight: 600;
        }

        .chevron {
          width: 36px;
          height: 36px;
          border-radius: 4px;
          justify-content: center;
          align-items: center;
          display: flex;
        }
      }
    }

    .questions {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 32px;
      width: 100%;
      min-width: 666px;
      .question {
        align-self: stretch;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        display: flex;
        padding-top: 16px;
        padding-bottom: 16px;

        .question-title {
          width: 100%;
          font-size: 22px;
          font-weight: 600;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: flex-start;
          justify-content: space-between;
          color: var(--color-N95);
          cursor: pointer;
        }

        .chevron {
          width: 36px;
          height: 36px;
          border-radius: 4px;
          justify-content: center;
          align-items: center;
          display: flex;
        }

        .answer {
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          color: var(--color-N65);
        }
      }
    }
  }
  @media only screen and (max-width: $size-tablet-max) {
  }
  .content-container,
  .questions {
    min-width: unset !important;
  }

  @media only screen and (max-width: $size-phablet-max) {
    .section {
      padding-bottom: 0px;
      .content-container {
        padding-bottom: 0px !important;
        width: 100% !important;
        min-width: unset;
        .content {
          gap: 24px !important;
          flex-direction: column !important;
          width: 100% !important;
          padding-bottom: 24px !important;
          .topics {
            overflow-x: scroll;
            display: flex;
            gap: 32px;
            flex-direction: row !important;
            justify-content: flex-start !important;
            align-items: center !important;

            margin-left: -24px;
            padding: 0 24px;
            width: calc(100% + 48px);

            .topic {
              min-width: unset;
              white-space: nowrap;
              &.active::before {
                content: "";
                position: relative;
                margin-bottom: -20px;
                height: 100%;
                width: 100%;
                height: 4px;
                background-color: var(--color-P55);
                transform: translate(0px, 30px);
                z-index: 1;
              }
            }
          }
          .questions {
            min-width: unset !important;
            .question {
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>
