<script>
  export let dark = false;
  export let white = false;
  export let small = false;
  export let purple = false;
</script>

<div
  class="eyebrow {dark ? ' dark' : ''}{small ? ' small' : ''}{white
    ? ' white'
    : ''}{purple ? ' purple' : ''}"
>
  <slot />
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .eyebrow {
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 20px;
    word-wrap: break-word;
    color: var(--color-N45);
    @media only screen and (max-width: $size-tablet-max) {
      font-size: 15px;
      line-height: 23px;
    }

    &.dark {
      color: $N05;
    }
    &.white {
      color: $white;
    }
    &.small {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
</style>
