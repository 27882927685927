<script>
  import Link from "components/elements/Link.svelte";

  export let parsedTOC = {};
  export let activeSubPage;
  export let mobile = false;
  export let navigateToPage;
  export let baseDocumentUrl;
  export let documentVersion;

  export let menuTitle = "";

  let isExpanded = false;
  let menuVisible;

  const onExpandMenu = () => (isExpanded = !isExpanded);

  const getActiveLabels = (toc, activePage) => {
    if (!activePage) {
      return ["", ""];
    }

    let page;
    let category;
    const found = Object.entries(toc).find(([header, data]) => {
      return Object.entries(data.pages).find(([pageLink, label]) => {
        if (pageLink === activePage) {
          page = label;
          return true;
        }
      });
    });
    category = found?.[0];
    return [category, page];
  };

  let activeCategory, activeLabel;
  $: menuVisible = !!Object.keys(parsedTOC).length && (isExpanded || !mobile);
  $: {
    [activeCategory, activeLabel] = getActiveLabels(parsedTOC, activeSubPage);
  }
</script>

<div class="document-menu" class:mobile>
  {#if mobile}
    <div class="mobile-toggle" on:click={onExpandMenu}>
      <div class="menu-title">
        <div>{activeLabel ?? menuTitle}</div>
      </div>
      {#if !isExpanded}
        <img src="../images/chevron-down.svg" />
      {:else}
        <img src="../images/chevron-up.svg" />
      {/if}
    </div>
  {/if}
  {#if menuVisible}
    <div class="menu-items">
      {#each Object.entries(parsedTOC) as [header, data]}
        <div class="menu-title"><div>{header}</div></div>
        {#each Object.entries(data.pages) as [pageLink, label]}
          <Link
            scrollTop
            href={`${baseDocumentUrl}${pageLink}`}
            class={`toc-link ${pageLink === activeSubPage ? "active" : ""}`}
            onClick={() => navigateToPage(pageLink)}
          >
            {label}
          </Link>
        {/each}
      {/each}
    </div>
  {/if}
</div>

<style lang="scss">
  @import "../../styles/global.scss";

  .document-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 16px;
    width: 100%;

    .menu-category {
      color: var(--color-N80);
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
    }

    .menu-items {
      margin-top: 24px;
      width: 100%;
      display: flex;
      gap: 12px;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      :global(.heading) {
        margin-top: 16px;
        padding-bottom: 8px;
      }
    }

    &.mobile {
      .menu-items {
        padding: 12px;
        margin-top: -2px;
        background-color: var(--color-N05);
        border-radius: 0px 0px 6px 6px;
        border: solid 1px var(--color-N25);
        :global(.toc-link.active) {
          color: var(--color-N95);
          &::before {
            display: none;
          }
        }
      }
      background: $white;
      align-self: flex-end;
      width: 100%;
      margin-top: 68px;
    }
    .mobile-toggle {
      border: solid 1px var(--color-N25);
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;
      cursor: pointer;
      width: 100%;
    }
    :global(.toc-link + .menu-title) {
      margin-top: 12px;
    }
    .menu-title {
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      min-width: fit-content;
      > div {
        color: var(--color-N95);
        font-size: 17px;
        font-weight: 600;
        line-height: 24px;
      }
      .menu-version {
        margin-left: 12px;
        font-weight: 400;
        color: var(--color-N95);
        padding: 0px;
      }
    }

    :global(.toc-link) {
      color: var(--color-N55);
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      word-wrap: break-word;
      &:hover {
        color: $green;
      }
    }

    :global(.toc-link:hover),
    :global(.toc-link.active) {
      color: var(--color-N95);
      font-weight: 600;
      line-height: 28px;
      word-wrap: break-word;
    }

    :global(.toc-link.active) {
      color: var(--color-N95);
      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 4px;
        height: 36px;
        background-color: var(--color-P55);
        transform: translate(-24px, -6px);
        border-radius: 2px;
        z-index: 1;
      }
    }
  }
  @media only screen and (max-width: $size-tablet-mid) {
    .document-menu {
      width: 100%;
      &.mobile {
        padding: 0px 24px;
        .mobile-toggle {
          width: 100%;
        }
        .menu-items {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
</style>
