<script>
  import PageSection from "components/widgets/PageSection.svelte";
  import AssetList from "components/elements/AssetList.svelte";

  const assets = [
    {
      symbol: "Au",
      name: "NobleGold",
      description:
        "A cornerstone of wealth preservation, gold has been cherished throughout the ages for its beauty, scarcity, and ability to retain value. Today, investors rely on gold as a trusted safe haven from financial turmoil and inflation.",
      price: "$ 4.05",
      grains: "31 / grain*",
      buttonText: "Buy NobleGold",
      iconColor: "#C89F4D",
    },
    {
      symbol: "Ag",
      name: "NobleSilver",
      description:
        "Silver also enjoys a rich legacy of enduring value as a monetary foundation throughout history. With modern relevance in broad industrial applications, silver resonates with those seeking practical utility and traditional stability.",
      price: "$ 0.05",
      grains: "0374 / grain*",
      buttonText: "Buy NobleSilver",
      iconColor: "#7F8690",
    },
    {
      symbol: "Pt",
      name: "NoblePlatinum",
      description:
        'Known as "white gold,” platinum has been prized for its lustrous beauty for centuries. Its resistance to corrosion, high melting point, and natural rarity have supported a strong demand from both industry and well-diversified investors.',
      price: "$ 2.55",
      grains: "12 / grain*",
      buttonText: "Buy NoblePlatinum",
      iconColor: "#7398D3",
    },
    // {
    //   symbol: "Pd",
    //   name: "NoblePalladium",
    //   description:
    //     "Palladium is another rare, silvery-white metal esteemed for its remarkable strength and vital role in cutting-edge technologies. Surging in popularity as a precious metal investment, palladium has earned a place in many balanced portfolios.",
    //   price: "$ 2.55",
    //   grains: "12 / grain*",
    //   buttonText: "Buy NoblePalladium",
    //   iconColor: "#C481B2",
    // },
  ];
</script>

<PageSection short>
  <AssetList title="A Precious Metals Solution for Every Investor" {assets} />
  <div class="coins">
    <img src="../images/gold_coins.webp" alt="Gold Coins" />
  </div>
</PageSection>

<style lang="scss">
  @import "../../../../styles/global.scss";
  .coins {
    padding-top: 64px;
    padding-bottom: 64px;
    img {
      width: 100%;
      max-width: 768px;
    }
  }
</style>
