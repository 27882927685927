<script>
    export let width = 80;
    export let height = 80;
    export let color = '#E6D097';
    export let ref = undefined;
  </script>
  
  <svg
    {ref}
    {width}
    {height}
    viewBox="0 0 {width} {height}"
    fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40 66.6668V33.3335" stroke="black" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M60 66.6668V13.3335" stroke="black" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20 66.6668V53.3335" stroke="black" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>