export const apiUrl = "BASE_API_URL";

export async function apiGet(endpoint, params = {}) {
  const url = new URL(`${apiUrl}${endpoint}`);
  url.search = new URLSearchParams(params).toString();
  return fetch(url)
    .then((r) => r.json())
    .catch((e) => {
      console.error(e);
      return null;
    });
}

export async function apiPost(endpoint, body = {}, headers = {}) {
  const url = new URL(`${apiUrl}${endpoint}`);
  const response = await fetch(url, {
    method: "POST",
    headers,
    body,
  });
  return response;
}

export async function apiPostJSON(endpoint, payload = {}, headers = {}) {
  const response = await apiPost(endpoint, JSON.stringify(payload), {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  });
  return response.json();
}
