<script>
  import LQNLogo from "components/elements/LQNLogo.svelte";
  import TextButton from "components/elements/TextButton.svelte";
  import MobileMenuButton from "components/widgets/MenuButton.svelte";
  import { COLOR_PRIMARY, COLOR_DARK } from "buttonStyles";
  import { BUTTON_DATA, SIGN_IN_LINK, isReferralPortal } from "utils/menu";
  import Btn from "elements/Btn.svelte";

  export let dark = false;
  export let blackHeader = false;
  export let padded = false;

  // References to TextButton objects
  let buttons = [];
  let activeButtonIdx = null;

  const registerLink = isReferralPortal() ? "#affiliate-login" : SIGN_IN_LINK;

  function onMenuButtonClick(idx) {
    if (activeButtonIdx === idx) {
      activeButtonIdx = null;
    } else {
      activeButtonIdx = idx;
    }
  }
</script>

<div
  class="header {dark ? 'dark' : ''} {blackHeader ? 'black' : ''} {padded
    ? 'padded'
    : ''}"
>
  <div class="inner-header columns">
    <div class="logo">
      <LQNLogo dark={padded} round={blackHeader} />
    </div>
    <div class="button-block">
      <div class="desktop-menu">
        {#each BUTTON_DATA as buttonData, i}
          <TextButton
            {dark}
            center
            noFill
            color={dark ? COLOR_DARK : COLOR_PRIMARY}
            onClick={() => onMenuButtonClick(i)}
            href={buttonData.link}
            bind:this={buttons[i]}
          >
            {buttonData.label}
          </TextButton>
        {/each}
        <Btn transparent {padded} bdr_white href={registerLink}
          >Register Now</Btn
        >
      </div>
      <div class="responsive-menu">
        <MobileMenuButton {dark} />
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @import "../../styles/global.scss";

  .header {
    @extend .generic-section;
    position: absolute;
    justify-content: center;
    background-color: transparent;
    padding: 24px;
    &.padded {
      padding-bottom: 28px;
      @media only screen and (max-width: $size-phone-max) {
        padding-bottom: 0px;
      }
    }
    @media only screen and (max-width: $size-phone-max) {
      padding: 12px;
    }

    .inner-header {
      display: flex;
      align-items: center;
      height: 64px;
      flex-direction: row;
      justify-content: space-between;
      @media only screen and (min-width: $size-phone-small) and (max-width: $size-phone-min) {
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        gap: 24px;
      }
      @media only screen and (max-width: $size-phone-small) {
        height: 48px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 24px;
      }
    }

    .button-block {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 48px;
      min-width: 380px;
      @media only screen and (max-width: $size-tablet-min) {
        min-width: unset;
      }

      .desktop-menu {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 48px;
        justify-content: flex-end;
        align-items: center;
        @media only screen and (max-width: $mobile-menu-width) {
          display: none;
        }
      }
      .responsive-menu {
        width: 100%;
        display: none;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 48px;
        justify-content: flex-end;
        :global(.button) {
          min-width: unset !important;
        }

        @media only screen and (max-width: $mobile-menu-width) {
          display: flex;
          width: fit-content;
          align-items: center;
          gap: 24px;
          div:first-child {
            display: none;
          }
        }
      }
    }

    :global(.button) {
      z-index: 2;
    }
  }

  .black {
    background-color: black;
    padding: 14px;
  }
</style>
