<script>
  import { onMount } from "svelte";

  export let light = false;
  export let white = false;
  export let dark = false;
  export let green = false;
  export let short = false;
  export let medium = false;
  export let long = false;
  export let hero = false;
  export let purple = false;
  export let heroPurple = false;
  export let grid = false;
  export let gold = false;
  export let light_blue = false;
  export let no_padding = false;

  export let delay = 300;
  let isVisible = false;

  onMount(() => {
    setTimeout(() => {
      isVisible = true;
    }, delay);
  });
</script>

<div
  class="section page-section {isVisible ? 'visible' : ''} {green
    ? 'green'
    : ''}{short ? 'short' : ''}
      {medium ? 'medium' : ''}{long ? 'long' : ''}{light ? ' light' : ''}
      {white ? ' white' : ''}
      {dark ? ' dark' : ''}{gold ? 'gold' : ''}{light_blue
    ? 'light_blue'
    : ''}{hero ? ' hero' : ''}
    {purple ? ' purple' : ''}
    {heroPurple ? ' hero-purple' : ''}
    {grid ? ' grid' : ''}
    {no_padding ? ' no_padding' : ''}
    "
>
  <slot />
</div>

<style lang="scss">
  @import "../../styles/global.scss";
  .section {
    background: var(--color-N05);
    display: flex;
    flex-direction: column;
    padding: 64px 0px;
    align-items: center;
    background-size: contain;
    background-position: top;
    background-repeat: repeat-x;
    @media only screen and (max-width: $size-tablet-max) {
      padding: 64px 0px;
    }
    @media only screen and (max-width: $size-phone-max) {
      padding: 64px 0px;
    }
    &.light {
      background: var(--color-N10);
    }
    &.white {
      background: #ffffff;
    }
    &.dark {
      background: var(--color-N90);
    }
    &.green {
      background: (linear-gradient(135deg, #353f37 0%, #13181c 100%));
    }
    &.gold {
      background: linear-gradient(
        36deg,
        #937131 2.66%,
        #eed680 25.82%,
        #cdb460 52.6%,
        #978348 78.65%,
        #fef7b3 98.44%
      );
    }
    &.light_blue {
      @media only screen and (max-width: $size-tablet-max) {
        padding-top: 0px;
      }
    }
    &.hero {
      padding: 256px 0px 128px 0px;
      color: var(--color-N05);
      background-image: url("../../images/hero-grid-8.svg"),
        linear-gradient(to top, var(--color-bg-dark), var(--color-bg-dark));

      @media only screen and (max-width: $size-tablet-max) {
        padding: 128px 0px 128px;
        background-image: url("../../images/hero-grid-16.svg"),
          linear-gradient(to top, var(--color-bg-dark), var(--color-bg-dark));
      }
    }
    &.purple {
      padding: 0px 0px 0px 0px;
      color: #ffffff;
      background-color: var(--color-bg-purple);
    }
    &.hero-purple {
      padding: 0px 0px 0px 0px;
      color: var(--color-N05);
      background-repeat: repeat;
      background-image: url("../../images/hero-grid-8.svg"),
        linear-gradient(to top, var(--color-bg-purple), var(--color-bg-purple));
    }
    &.grid {
      padding: 64px 0px;
      color: var(--color-N05);
      background-image: url("../../images/dark-grid-8.svg"),
        linear-gradient(to top, var(--color-bg-purple), var(--color-bg-purple));
      background-size: contain;
    }
    &.short {
      padding: 0px 0px;
      @media only screen and (max-width: $size-tablet-max) {
        padding: 24px 0px;
      }
    }
    &.medium {
      padding: 64px 0px;
    }
    &.long {
      padding: 160px 0px 128px;

      @media only screen and (max-width: $size-tablet-max) {
        padding: 96px 0px;
      }
    }
    &.no_padding {
      padding: 0px 0px 0px;
    }
  }
</style>
