<script context="module">
  import branch from "branch-sdk";

  let branchPromise = null;
  const branchApiKey = "BRANCH_API_KEY";

  export async function getBranchData() {
    const data = branchPromise
      ? await branchPromise
      : new Promise((reject) => {
          reject("Promise not initialized");
        });
    return data;
  }

  export function initBranch() {
    if (!branchApiKey || branchApiKey.includes("BRANCH_API")) {
      return;
    }
    branchPromise = new Promise((resolve, reject) => {
      branch.init(branchApiKey, {}, function (err, data) {
        const existingBranchData = window.localStorage.getItem("__BRANCH__");
        let existingBranchDataParsed = null;
        if (existingBranchData) {
          existingBranchDataParsed = JSON.parse(existingBranchData);
          // Do not overwrite existing referral code
          if (existingBranchDataParsed["referral_code"]) {
            resolve(existingBranchDataParsed);
            return;
          }
        }

        if (data && Object.keys(data.data_parsed).length) {
          const branchPayload = data.data_parsed;

          window.localStorage.setItem(
            "__BRANCH__",
            JSON.stringify(branchPayload)
          );
          resolve(branchPayload);
        }

        if (existingBranchDataParsed) {
          resolve(existingBranchDataParsed);
        }

        reject("No branch data");
      });
    });
  }
</script>
