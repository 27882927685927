<script>
  import LQNLogo from "components/elements/LQNLogo.svelte";
  import SocialLinks from "components/widgets/SocialLinks.svelte";
  import Link from "../elements/Link.svelte";

  export let disclaimer = "";
</script>

<div class="footer">
  <div class="footer-content">
    <div class="logo">
      <LQNLogo round />
      <div class="social-links">
        <SocialLinks />
      </div>
    </div>
    <div class="disclaimer">
      {#if disclaimer}
        {@html disclaimer}
      {/if}
      Digital assets are subject to a number of risks, including price volatility.
      Transacting in digital assets could result in significant losses and may not
      be suitable for some consumers. Digital asset markets and exchanges are not
      regulated with the same controls or customer protections available with other
      forms of financial products and are subject to an evolving regulatory environment.
      Digital assets do not typically have legal tender status and are not covered
      by deposit protection insurance. The past performance of a digital asset is
      not a guide to future performance, nor is it a reliable indicator of future
      results or performance.
      <br /><br />
      ABN 71 674 454 776
    </div>
    <div class="bottom-links">
      <div>© 2024 Liquid Noble Pty Ltd</div>
      <Link scrollTop href="/legal/terms-and-conditions" class="footer-link"
        >Terms of Use</Link
      >
      <Link scrollTop href="/legal/privacy-policy" class="footer-link"
        >Privacy Policy</Link
      >
      <!-- <Link scrollTop href="/">Cookie Preferences</Link> -->
    </div>
  </div>
</div>

<style lang="scss">
  @import "../../styles/global.scss";
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #000000;
    padding: 64px 40px 64px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .footer-content {
      width: 100%;
      max-width: 1088px;
      display: flex;
      flex-direction: column;
    }

    .logo {
      margin-bottom: 48px;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .social-links {
      display: flex;
      align-items: center;
    }

    .disclaimer {
      margin-top: 24px;
      color: white;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      max-width: 896px;
    }

    .bottom-links {
      margin-top: 24px;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      color: white;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      :global(.footer-link) {
        color: white;
        font-size: 14px;
        line-height: 21px;
        &:hover {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    @media only screen and (max-width: $size-phablet-max) {
      padding: 64px 24px;
    }
  }
</style>
