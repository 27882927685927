<script>
  export let dark = false;
  export let large = false;
  export let extralarge = false;
  export let small = small;
  export let style = "";
  export let gold = false;
</script>

<div
  class="heading {$$props.class} {dark ? 'dark' : ''} {large
    ? 'large'
    : ''}  {extralarge ? 'extralarge' : ''}  {small ? 'small' : ''}{gold
    ? 'gold'
    : ''}"
  {style}
>
  <slot />
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .heading {
    display: flex;
    font-weight: 700;
    color: $white;
    letter-spacing: 0.015em;
  }
  .heading1,
  .heading2,
  .heading3 {
    @media only screen and (max-width: $size-tablet-max) {
      padding-bottom: 16px;
    }
  }
  .heading4,
  .heading5,
  .heading6 {
  }
  .heading1 {
    font-size: 48px;
    line-height: normal;
    @media only screen and (max-width: $size-tablet-max) {
      font-size: 42px;
      line-height: 55px;
    }
  }
  .heading2 {
    font-size: 48px;
    line-height: 55px;
    @media only screen and (max-width: $size-tablet-max) {
      font-size: 35px;
      line-height: 46px;
    }
  }
  .heading3 {
    font-size: 32px;
    line-height: 48px;
    @media only screen and (max-width: $size-tablet-max) {
      font-size: 32px;
      line-height: 50px;
    }
  }
  .heading4 {
    font-size: 24px;
    line-height: 30px;
  }
  .heading5 {
    font-size: 18px;
    line-height: 22px;

    &.small {
      font-size: 17px;
      line-height: 25px;
    }
  }
  .heading6 {
    font-size: 16px;
    line-height: 20px;
  }
  .dark {
    color: $G95;
  }
  .gold {
    background: #937131;
    background: -webkit-linear-gradient(
      to right,
      #937131 0%,
      #eed680 25%,
      #cdb460 50%,
      #978348 75%,
      #fef7b3 100%
    );
    background: -moz-linear-gradient(
      to right,
      #937131 0%,
      #eed680 25%,
      #cdb460 50%,
      #978348 75%,
      #fef7b3 100%
    );
    background: linear-gradient(
      to right,
      #937131 0%,
      #eed680 25%,
      #cdb460 50%,
      #978348 75%,
      #fef7b3 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
</style>
