<script>
  import { onMount, tick } from "svelte";
  import Nav from "components/Nav.svelte";
  import Footer from "components/widgets/Footer.svelte";
  export let dark = false;
  export let blackHeader = false;
  export let paddedHeader = false;
  export let noHeader = false;
  export let footerDisclaimer = "";

  onMount(async () => {
    // Finish rendering
    await tick();
    // Scroll to the top
    window.scrollTo(0, 0);
  });
</script>

{#if !noHeader}
  <Nav {dark} {blackHeader} {paddedHeader} />
{/if}
<div>
  <slot />
</div>

<Footer disclaimer={footerDisclaimer} />
