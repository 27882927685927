<script context="module">
  export const ICONS = {
    // Static icons
    "logo-default": LogoDefaultSVG,

    // Customizable icons (Svelte components)
    plus: PlusSVG,
    shield: ShieldSVG,
    chart: ChartSVG,
    menu: MenuSVG,
    x: XSVG,
    "social-x": SocialXSVG,
    "social-facebook": SocialFacebookSVG,
    "social-telegram": SocialTelegramSVG,
    "social-linkedin": SocialLinkedInSVG,
    "social-instagram": SocialInstagramSVG,
    "social-mail": SocialMailSVG,
    "arrow-right": ArrowRightSVG,
    "chevron-up": ChevronUpSVG,
  };
</script>

<script>
  import LogoDefaultSVG from "components/elements/svg/LogoDefault.svelte";
  import PlusSVG from "components/elements/svg/Plus.svelte";
  import ShieldSVG from "components/elements/svg/Shield.svelte";
  import ChartSVG from "components/elements/svg/Chart.svelte";
  import MenuSVG from "components/elements/svg/Menu.svelte";
  import XSVG from "components/elements/svg/X.svelte";
  import ArrowRightSVG from "components/elements/svg/ArrowRight.svelte";
  import SocialXSVG from "components/elements/svg/SocialX.svelte";
  import SocialFacebookSVG from "components/elements/svg/SocialFacebook.svelte";
  import SocialTelegramSVG from "components/elements/svg/SocialTelegram.svelte";
  import SocialLinkedInSVG from "components/elements/svg/SocialLinkedIn.svelte";
  import SocialInstagramSVG from "components/elements/svg/SocialInstagram.svelte";
  import SocialMailSVG from "components/elements/svg/SocialMail.svelte";
  import ChevronUpSVG from "components/elements/svg/ChevronUp.svelte";

  // Props
  export let name;
  export let width = undefined;
  export let height = undefined;
  export let color = undefined;
  export let colorName = undefined;
  export let ref = undefined;

  let IconComponent;
  // Reactively replace icon if name changes for the same component instance
  $: IconComponent = ICONS[name];
</script>

<svelte:component
  this={IconComponent}
  {width}
  {height}
  {ref}
  color={!!colorName ? `var(--color-${colorName})` : color}
/>
