<script>
    export let width = 24;
    export let height = 24;
    export let color = "#E6D097";
    export let ref = undefined;
  </script> 
   
  <svg
    {ref}
    {width}
    {height}
    viewBox="0 0 {width} {height}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  