<script>
  export let dark = false;
  export let white = false;
</script>

<div
  class="body {dark ? 'dark' : ''}{white ? 'white' : ''} {$$props.class ?? ''}"
>
  <slot />
</div>

<style lang="scss">
  @import "../../../styles/global.scss";

  .body {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: $darkest;

    &.dark {
      color: $purple;
    }
    &.white {
      color: var(--color-N10);
    }
  }
</style>
